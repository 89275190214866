import { FC } from 'react'
import { BodySmall, Title6 } from './styles/Typography'
import { Grid } from './Grid'
import { SuperImage } from './SuperImage'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Accordion } from './Accordion'
import { IFaqSectionFields, IFaqItem } from '@estercare/ester-shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getOptions } from './richText/options'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isDesktop } from '../utils/devices'
import { Arrow } from './icons'
import { useSelectIsAdmin } from '../state/selectors'

export const FAQItem: FC<{ faq: IFaqItem }> = ({ faq }) => {
  const { question, answer } = faq.fields
  if (!question || !answer) return null
  return <Accordion title={question}>{answer && documentToReactComponents(answer, getOptions(true))}</Accordion>
}

export const FAQ: FC<IFaqSectionFields & { showSupportLink?: boolean }> = ({
  faqItems,
  imageAlignment,
  image,
  showSupportLink = false,
  backgroundColor,
}) => {
  const width = useWindowWidth()
  const isAdmin = useSelectIsAdmin()

  return (
    <StyledGrid $columns={isDesktop(width) ? 2 : 1} $backgroundColor={backgroundColor}>
      <Container $imageAlignment={imageAlignment}>
        <SuperImage asset={image} width={300} height={400} />
      </Container>
      <Container>
        <StyledTitle6>Vanliga frågor</StyledTitle6>

        {faqItems && faqItems?.map((faq, index) => <FAQItem faq={faq} key={index} />)}
        {showSupportLink && (
          <StyledLinkBodySmall>
            Hittar du inte svaret på det du letar efter?{' '}
            <Link to={`/${isAdmin ? 'admin' : 'portal'}/support`}>
              <StyledLinkSpan>
                Besök supporten <Arrow size={16} />
              </StyledLinkSpan>
            </Link>
          </StyledLinkBodySmall>
        )}
      </Container>
    </StyledGrid>
  )
}
type BackgroundColor = 'beigeDark' | 'beigeLight'

const StyledGrid = styled(Grid)<{ $backgroundColor?: BackgroundColor }>`
  background-color: ${({ $backgroundColor }) =>
    ($backgroundColor && theme.color[$backgroundColor]) || theme.color.beigeLight};
`

const Container = styled.div<{ $imageAlignment?: boolean }>`
  order: ${({ $imageAlignment }) => ($imageAlignment ? 1 : 0)};
  justify-self: ${({ $imageAlignment }) => ($imageAlignment ? 'center' : 'start')};
  align-self: center;

  @media screen and (max-width: ${theme.breakpoint.large}px) {
    &:first-child {
      display: none;
    }
  }
`

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.medium}px;
`

const StyledLinkBodySmall = styled(BodySmall)`
  && {
    font-weight: 375;
    font-size: 14.8px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: ${theme.spacing.tiny}px;
  }
`
const StyledLinkSpan = styled.span`
  color: ${theme.color.plumMid};
  display: inline-flex;
  align-items: center;
  gap: ${theme.spacing.tiny}px;
  font-weight: 500;
`
