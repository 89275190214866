import { theme } from '../../theme/theme'
import { IconProps } from './types'
const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.plumMid

export const Download = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15L12 4" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7 11.6751L11.9333 16L17 11.7391"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6 20H18" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
  </svg>
)
