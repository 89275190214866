import styled from 'styled-components'
import { BodySmall, Title6 } from './styles/Typography'
import { theme } from '../theme/theme'
import { Link } from 'react-router-dom'
import { FC } from 'react'
import { Tag } from './Tag'

interface Props {
  url: string
  imageUrl: string
  title: string
  description: string
  duration: number
  completed: boolean
}

export const TrainingCard: FC<Props> = ({ url, imageUrl, title, description, duration, completed }) => {
  return (
    <Link to={url}>
      <Card>
        <CardImage src={imageUrl} />
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
          <CardStats>
            <span>Längd: ~{duration} min</span>
            {completed && <Tag text="Avklarad" />}
          </CardStats>
        </CardContent>
      </Card>
    </Link>
  )
}

const Card = styled.div`
  width: 100%;
  border: 1px solid ${theme.color.beigeDark};
  border-radius: ${theme.spacing.medium}px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
  background: ${theme.color.white};
`
const CardImage = styled.img`
  width: 100%;
  height: 232px;
  border-radius: ${theme.spacing.medium}px;
  object-fit: cover;
`
const CardContent = styled.div`
  padding: ${theme.spacing.xlarge}px;
`
const CardTitle = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
  color: ${theme.color.black};
`
const CardDescription = styled(BodySmall)`
  color: ${theme.color.grey4};
  margin-bottom: ${theme.spacing.mediumLarge}px;
  height: 68px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
`

const CardStats = styled(BodySmall)`
  color: ${theme.color.grey4};
  display: flex;
  justify-content: space-between;
`
