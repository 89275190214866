import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { MenuItem } from '../../utils/menus/types'
import { SidebarUserMenu } from './SidebarUserMenu'
import { FC } from 'react'
import { MenuListItem } from './MenuListItem'

interface Props {
  menuItems: MenuItem[]
}

export const DesktopSidebar: FC<Props> = ({ menuItems }) => {
  return (
    <SidebarContainer>
      <MenuContainer>
        {menuItems.map((item, index) => {
          return <MenuListItem key={index} item={item} />
        })}
      </MenuContainer>
      <SidebarUserMenu />
    </SidebarContainer>
  )
}

const MenuContainer = styled.div`
  padding: ${theme.spacing.tiny}px ${theme.spacing.small}px ${theme.spacing.medium}px ${theme.spacing.small}px;
`

const SidebarContainer = styled.div`
  width: 280px;
  background: ${theme.color.beigeLight};
  border-right: 1px ${theme.color.grey2} solid;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;

  background-color: ${theme.color.beigeLight};
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    display: none;
  }
`
