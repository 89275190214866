import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyLarge } from './styles/Typography'

interface Props<T> {
  component: string
  options: readonly T[]
  setChange: (arg: T) => void
  defaultValue?: T
}

export const Radios = <T,>({ options, component, setChange, defaultValue }: Props<T>) => {
  return (
    <form>
      <RadiosContainer>
        {options.map((option, index) => {
          return (
            <BodyLarge key={index}>
              <label>{String(option)}</label>
              <input
                type="radio"
                value={String(option)}
                onChange={(event) => setChange(event.target.value as T)}
                name={component}
                defaultChecked={defaultValue ? option === defaultValue : index === 0}
              />
            </BodyLarge>
          )
        })}
      </RadiosContainer>
    </form>
  )
}

const RadiosContainer = styled.div`
  background-color: ${theme.color.plum};
  width: 100%;
  padding: ${theme.spacing.small}px ${theme.spacing.mediumLarge}px;
  display: flex;
  align-items: center;
  color: ${theme.color.white};

  input {
    margin-right: ${theme.spacing.mediumLarge}px;
  }
`
