import { getBackgroundColor, getIcon, parseContenfulImageUrl } from '../contentful/utils'
import { theme } from '../theme/theme'
import { BlockItem } from './BlockItem'
import { Title6 } from './styles/Typography'
import styled from 'styled-components'
import { FC } from 'react'
import { isTablet, isMobile } from '../utils/devices'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { LinkButton } from './buttons/link/LinkButton'
import { RichText } from './richText/RichText'
import { ITextWithImageBlockFields } from '@estercare/ester-shared'

export const TextWithImageCard: FC<ITextWithImageBlockFields> = ({
  icon,
  title,
  bodyText,
  link,
  image,
  backgroundColor = 'white',
  button,
  showImageInMobile,
}) => {
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)
  const direction = isMobileDevice ? 'column' : 'row'
  const hideImage = Boolean(!showImageInMobile && isMobileDevice)
  const height = isMobileDevice && !hideImage ? 572 : 320

  const url = link?.fields.url
  const buttonText = link?.fields.label
  const useWhiteText = Boolean(backgroundColor === 'plum')

  return (
    <CardContainer>
      <BlockItem backgroundColor={getBackgroundColor(backgroundColor)} direction={direction} height={height}>
        <ContentContainer>
          <>
            {icon && (
              <IconContainer $isMobileDevice={isMobileDevice} $useWhiteText={useWhiteText}>
                {getIcon(icon, isMobileDevice ? 18 : 24, useWhiteText ? theme.color.white : theme.color.plumMid)}
              </IconContainer>
            )}
            <StyledTitle6 $useWhiteText={useWhiteText}>{title}</StyledTitle6>
            <TextContainer $useWhiteText={useWhiteText}>{bodyText && <RichText richText={bodyText} />}</TextContainer>
          </>
          {url && buttonText && (
            <LinkButton
              href={url}
              text={buttonText}
              secondary={Boolean(button === 'Secondary')}
              buttonColor={useWhiteText ? theme.color.white : theme.color.plum}
              buttonStyle={{ minWidth: 'fit-content' }}
            />
          )}
        </ContentContainer>
        {!hideImage && <ImageContainer>{image && <StyledImage src={parseContenfulImageUrl(image)} />}</ImageContainer>}
      </BlockItem>
    </CardContainer>
  )
}

export const CardContainer = styled.div`
  margin-bottom: ${theme.spacing.medium}px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 50%;
  padding: ${theme.spacing.large}px;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    width: 100%;
    height: 100%;
    a {
      width: 100%;
    }
  }
`

const IconContainer = styled.div<{ $isMobileDevice: boolean; $useWhiteText: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $isMobileDevice }) => ($isMobileDevice ? 32 : 40)}px;
  height: ${({ $isMobileDevice }) => ($isMobileDevice ? 32 : 40)}px;
  border-radius: 50%;
  background-color: ${({ $useWhiteText }) => ($useWhiteText ? 'rgba(255, 255, 255, 0.1)' : theme.color.plumlight)};
  margin-bottom: ${({ $isMobileDevice }) => ($isMobileDevice ? theme.spacing.xsmall : theme.spacing.medium)}px;
`

const TextContainer = styled.div<{ $useWhiteText: boolean }>`
  margin-bottom: ${theme.spacing.mediumLarge}px;
  color: ${({ $useWhiteText }) => ($useWhiteText ? theme.color.white : theme.color.grey4)};
`

const StyledTitle6 = styled(Title6)<{ $useWhiteText: boolean }>`
  color: ${({ $useWhiteText }) => ($useWhiteText ? theme.color.white : theme.color.black)};
  margin-bottom: ${theme.spacing.xsmall}px;
`

const ImageContainer = styled.div`
  display: flex;
  width: 50%;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    width: 100%;
    max-height: 50%;
    order: -1;
  }
  @media screen and (max-width: ${theme.breakpoint.small}px) {
    max-height: 45%;
  }
`

const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 16px;
`
