import { ILinkListFields, isPortalGeneralPage } from '@estercare/ester-shared'
import { FC } from 'react'
import { PreviewGuard } from './PreviewGuard'
import { SupportCard } from './SupportCard'
import { Grid } from './Grid'

interface Props {
  linkList: ILinkListFields
}

export const SupportBlock: FC<Props> = ({ linkList }) => {
  const supportPageLinks = linkList.links
  if (!supportPageLinks) return <PreviewGuard />

  return (
    <Grid $columns={3}>
      {supportPageLinks.map((link, index) => {
        if (isPortalGeneralPage(link) && link.fields.slug && link.fields.title && link.fields.iconType) {
          const { slug, title, iconType } = link.fields
          return <SupportCard key={index} url={slug} title={title} icon={iconType} />
        } else return <PreviewGuard key={index} />
      })}
    </Grid>
  )
}
