import { IHighlightedListBlockFields } from '@estercare/ester-shared'
import styled from 'styled-components'
import { Title2 } from './styles/Typography'
import { theme } from '../theme/theme'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isLowerThenCustomBreakpoint } from '../utils/devices'
import { ScreenItem } from './ListScreenItem'

type Props = IHighlightedListBlockFields

export const ListScreenItems = ({ title, listItems }: Props) => {
  const width = useWindowWidth()
  const isLowerThenLowerThen1150 = isLowerThenCustomBreakpoint(width, theme.breakpoint.xlarge)

  return (
    <Container>
      <StyledTitle2>{title}</StyledTitle2>
      <ListemItemsWrapper>
        {listItems &&
          listItems.map(({ fields: { text, title } }, index) => (
            <ScreenItem
              isMobileDevice={isLowerThenLowerThen1150}
              listLength={listItems.length}
              key={title}
              text={text || ''}
              index={index}
            />
          ))}
      </ListemItemsWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: start;
  overflow: scroll;
  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    flex-direction: column;
  }
`

const StyledTitle2 = styled(Title2)<{ $textColor?: string }>`
  color: ${theme.color.white};
  width: 392px;
  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    width: 100%;
  }
`

const ListemItemsWrapper = styled.div`
  margin-left: ${theme.spacing.mediumLarge}px;
  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    margin-left: 0;
    margin-top: 24px;
  }
`
