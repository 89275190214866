import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 102
const DEFAULT_COLOR = theme.color.red

export const ErrorSign = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17ZM11 13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V7C13 6.44771 12.5523 6 12 6C11.4477 6 11 6.44771 11 7V13Z"
        fill={color}
      />
    </svg>
  )
}
