import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Spinner } from './icons/Spinner'

const DEFAULT_SIZE = 15
const DEFAULT_COLOR = theme.color.white

export const LoadingSpinner = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: { size?: number; color?: string }) => {
  return (
    <LoadingContainer>
      <Spinner size={size} color={color} />
    </LoadingContainer>
  )
}

const LoadingContainer = styled.div`
  svg {
    margin-left: ${theme.spacing.tiny}px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
