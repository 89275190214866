import { appConfig } from '../../../../config'
import { HealthCallUserInformation } from './types'

export const getActiveHealthCalls = async ({ accessToken }: { accessToken: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/healthCalls/active`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}

export const postHealthCall = async ({
  accessToken,
  userInformation,
}: {
  accessToken: string
  userInformation: HealthCallUserInformation
}): Promise<Response> => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/healthCalls`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(userInformation),
  })

  if (!res.ok) {
    throw new Error('Failed to post health call')
  }
  return res
}
