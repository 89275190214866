import { styled, keyframes } from 'styled-components'
import { IconProps } from './types'
import { theme } from '../../theme/theme'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.white

export const Spinner = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <StyledSvg width={size} height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
    />
  </StyledSvg>
)

const spinner = keyframes`

  100%{transform:rotate(360deg)}
`
const StyledSvg = styled.svg`
  transform-origin: center;
  animation: ${spinner} 0.75s infinite;
`
