import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Close = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, ...rest }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M18 6L6 18" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 6L18 18" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
