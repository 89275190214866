export const fontSize = {
  xxsmall: 10.5,
  xsmall: 12.8,
  small: 14.4,
  default: 16,
  medium: 18,
  mediumLarge: 20,
  large: 22,
  xlarge: 28,
  xxlarge: 36,
  jumbo: 44,
}
