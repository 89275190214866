import { theme } from '../../theme/theme'
import { IconProps } from './types'
const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.plum

export const VideoCamera = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1667 6H5.16667C3.69391 6 2.5 7.19391 2.5 8.66667V15.3333C2.5 16.8061 3.69391 18 5.16667 18H13.1667C14.6394 18 15.8333 16.8061 15.8333 15.3333V8.66667C15.8333 7.19391 14.6394 6 13.1667 6Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5 14.6665L21.5356 16.1839C21.6372 16.2346 21.7501 16.2585 21.8636 16.2534C21.9771 16.2482 22.0874 16.2142 22.184 16.1544C22.2806 16.0946 22.3603 16.0112 22.4157 15.912C22.471 15.8127 22.5 15.701 22.5 15.5874V8.41142C22.5001 8.2978 22.4711 8.18605 22.4158 8.08681C22.3605 7.98756 22.2807 7.90411 22.184 7.84442C22.0874 7.78472 21.977 7.75076 21.8635 7.74576C21.75 7.74076 21.6371 7.7649 21.5356 7.81587L18.5 9.3332"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
