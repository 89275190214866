import { font } from './font'
import { fontSize } from './fontSize'
import { color } from './colors'
import { spacing } from './spacing'
import { breakpoint } from './breakpoints'

export const theme = {
  color,
  font,
  fontSize,
  spacing,
  breakpoint,
}
