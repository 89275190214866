import { ContentfulClientApi, createClient, CreateClientParams } from 'contentful'

import { env } from '../utils/environments/environmentVariables'

const ContentfulClientOptions: CreateClientParams = {
  space: 'hr4hxqu06b00',
  accessToken: env.optional('VITE_CONTENTFUL_ACCESS_TOKEN') || '',
  ...(!env.useDeliveryApi && !env.isProd('VITE_NODE_ENV') && { host: 'preview.contentful.com' }),
}

const contentfulClient = null

export const getContentfulClient = (): ContentfulClientApi => {
  return contentfulClient || createClient(ContentfulClientOptions)
}
