import { ICollection } from '@estercare/ester-shared'
import { FC } from 'react'
import styled from 'styled-components'
import { Title6 } from './styles/Typography'
import { PreviewGuard } from './PreviewGuard'
import { BlockItem } from './BlockItem'
import { Grid } from './Grid'
import { theme } from '../theme/theme'
import { CollectionItem } from './CollectionItem'

interface Props {
  collection: ICollection
  horizontal?: boolean
}

export const Collection: FC<Props> = ({ collection, horizontal = true }) => {
  const { title, items } = collection.fields
  if (!items) return <PreviewGuard />
  const height = horizontal ? 215 : undefined
  const columns = horizontal ? 2 : 4
  const collectionItems = items.map((item, index) => {
    return (
      <BlockItem key={index} height={height}>
        <CollectionItem item={item} />
      </BlockItem>
    )
  })
  return (
    <>
      <Title6>{title}</Title6>
      <StyledGrid $horizontal={horizontal} $columns={columns}>
        {collectionItems}
      </StyledGrid>
    </>
  )
}

const StyledGrid = styled(Grid)<{ $horizontal?: boolean }>`
  margin-top: ${theme.spacing.medium}px;

  ${({ $horizontal }) =>
    !$horizontal &&
    `
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      gap: ${theme.spacing.xsmall}px;
      justify-content: space-between;
      
      * img {
        object-position: center;
      }

      @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
        grid-template-columns: repeat(2, minmax(230px, 1fr));
      }

      @media screen and (max-width: ${theme.breakpoint.small}px) {
        justify-content: center;
      }

      @media screen and (max-width: ${theme.breakpoint.xsmall}px) {
        grid-template-columns: repeat(1, minmax(230px, 350px));
      }
    `}
`
