import React from 'react'
import { Table as MuiTable, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material'
import { theme } from '../theme/theme'
import { BodySmallMedium } from './styles/Typography'

type TableProps = {
  data: Record<string, React.ReactNode>[]
  headers?: Record<string, React.ReactNode>
}

export const Table = ({ data, headers }: TableProps) => {
  if (data.length === 0) return null
  const keys = Object.keys(data[0] || {})

  return (
    <StyledTableContainer>
      <MuiTable>
        <TableHead>
          <TableRow>
            {keys.map((key) => (
              <StyledTableCell key={key}>
                <StyledBodySmallMedium>{headers?.[key]}</StyledBodySmallMedium>
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {keys.map((key) => (
                <StyledTableCell key={key} align="left">
                  {row[key]}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled(TableContainer)`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.beigeDark};
  border-radius: ${theme.spacing.medium}px;
`

const StyledTableCell = styled(TableCell)`
  padding: ${theme.spacing.medium}px;
`
const StyledBodySmallMedium = styled(BodySmallMedium)`
  text-transform: capitalize;
`
