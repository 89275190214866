import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Fan = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3877 9.80808C15.9948 8.69537 15.267 7.7316 14.3043 7.04914C13.3416 6.36667 12.1912 5.99899 11.0112 5.99659C9.8311 5.99419 8.67924 6.35719 7.71378 7.03573C6.74832 7.71427 6.01661 8.67507 5.61914 9.78618"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11.7145L1 8.09549C1.72499 6.01831 3.07932 4.21872 4.87461 2.94704C6.6699 1.67535 8.8169 0.994792 11.017 1.00003C13.217 1.00527 15.3607 1.69604 17.15 2.97626C18.9392 4.25648 20.2849 6.0625 21 8.14311L11 11.7145Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3334 12.9144C14.0872 13.6069 13.633 14.2064 13.0329 14.6309C12.4329 15.0554 11.7163 15.284 10.9813 15.2856C10.2463 15.2872 9.52877 15.0616 8.92692 14.6396C8.32507 14.2177 7.8683 13.6201 7.61914 12.9286L11.0001 11.7144L14.3334 12.9144Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.58984 3.16895L7.52127 7.18228" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.4071 3.17334L14.4785 7.18191" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 1.00049V6.00049" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
