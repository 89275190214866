import { HeartPalpitation } from '../../components/icons/HeartPalpitation'
import { Home } from '../../components/icons/Home'
import { Paper } from '../../components/icons/Paper'
import { QuestionMark } from '../../components/icons/QuestionMark'
import { Search } from '../../components/icons/Search'
import { MenuItem } from './types'

export const employeeMenu: MenuItem[] = [
  { name: 'Hem', path: '/portal', icon: Home },
  { name: 'Hälsotjänster', path: '/portal/halsotjanster', icon: HeartPalpitation },
  { name: 'Utbildning', path: '/portal/utbildning', icon: Paper },
  { name: 'Kunskapsbank', path: '/portal/kunskapsbank', icon: Search },
  { name: 'Support', path: '/portal/support', icon: QuestionMark },
]
