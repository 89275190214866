import { adminMenu } from './adminMenu'
import { componentLibraryMenu, isComponentLibraryMenu } from './componentLibraryMenu'
import { employeeMenu } from './employeeMenu'
import { MenuItem } from './types'

export const getMenu = (showAdminMenu: boolean, currentRoute?: string): MenuItem[] => {
  if (currentRoute && isComponentLibraryMenu(currentRoute)) {
    return componentLibraryMenu
  } else if (showAdminMenu) {
    return adminMenu
  } else {
    return employeeMenu
  }
}
