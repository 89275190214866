import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Dots = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="4" r="2" transform="rotate(90 12 4)" fill={color} />
      <circle cx="12" cy="12" r="2" transform="rotate(90 12 12)" fill={color} />
      <circle cx="12" cy="20" r="2" transform="rotate(90 12 20)" fill={color} />
    </svg>
  )
}
