import { useAuth0 } from '@auth0/auth0-react'
import { ViewLayout } from '../components/layouts/ViewLayout'
import { ComponentLayout } from '../components/layouts/ComponentLayout'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const Index = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate('/portal')
      } else {
        navigate('/login')
      }
    }
  }, [isLoading, isAuthenticated, navigate])

  return (
    <ViewLayout>
      <ComponentLayout>{isLoading && <LoadingSpinner />}</ComponentLayout>
    </ViewLayout>
  )
}
