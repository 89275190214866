import { FC, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'

export const BaseLayout: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <BaseLayoutContainer $height={windowHeight}>{children}</BaseLayoutContainer>
}

const BaseLayoutContainer = styled.div<{ $height: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: ${({ $height }) => `${$height}px`};
  overflow-y: scroll;
  overflow-x: hidden;
`
