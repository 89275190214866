import styled from 'styled-components'
import { theme } from '../theme/theme'
import { ModalCloseButton } from './buttons/ModalCloseButton'
import { PropsWithChildren } from 'react'

interface ModalProps extends PropsWithChildren {
  onClose?: () => void
  backgroundColor?: string
  disableClose?: boolean
}

export const Modal = ({ onClose, disableClose, children, backgroundColor = theme.color.white }: ModalProps) => {
  const handleClose = () => {
    if (!onClose || disableClose) {
      return
    }
    onClose()
  }
  return (
    <ModalContainer onClick={handleClose}>
      <ModalDiv onClick={(e) => !disableClose && e.stopPropagation()} $backgroundColor={backgroundColor}>
        {!disableClose && <ModalCloseButton onClick={handleClose} />}
        {children}
      </ModalDiv>
    </ModalContainer>
  )
}

export const ModalContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${theme.color.modalBackdrop};
  z-index: 10000;
  align-items: center;
  justify-content: center;
`

const ModalDiv = styled.div<{ $backgroundColor: string }>`
  position: relative;
  background-color: ${({ $backgroundColor }) => `${$backgroundColor}`};
  overflow: scroll;
  padding: ${theme.spacing.large}px;
  border-radius: ${theme.spacing.medium}px;
  pointer-events: auto;
  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.xxlarge}px;
  }
`
