import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Home = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.1543 13.6074V20.4446H10.1321V15.4721C10.1321 15.1424 10.2632 14.8262 10.4966 14.5931C10.7299 14.36 11.0465 14.229 11.3765 14.229H12.621C12.951 14.229 13.2675 14.36 13.5009 14.5931C13.7343 14.8262 13.8654 15.1424 13.8654 15.4721V20.4446H18.8432V13.6074"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66602 12.3642L11.1191 3.92005C11.2347 3.80453 11.3719 3.71289 11.5229 3.65037C11.674 3.58784 11.8359 3.55566 11.9993 3.55566C12.1628 3.55566 12.3247 3.58784 12.4758 3.65037C12.6268 3.71289 12.764 3.80453 12.8796 3.92005L21.3327 12.3642"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
