import { IHelpAreaFields, IArticleFields, IPodcastEpisodeFields } from '@estercare/ester-shared'
import { Entry } from 'contentful'

export const filterArchiveByRelatedArea = (
  helpArea: Entry<IHelpAreaFields>,
  articles?: IArticleFields[],
  podcastEpisodes?: IPodcastEpisodeFields[]
) => {
  const relatedArticles = articles?.filter((article) =>
    article?.relatedHelpAreas?.some((relatedHelpArea) => relatedHelpArea.fields.name === helpArea.fields.name)
  )

  const relatedPodcasts = podcastEpisodes?.filter((podcast) =>
    podcast?.relatedHelpAreas?.some((relatedHelpArea) => relatedHelpArea.fields.name === helpArea.fields.name)
  )

  return { relatedArticles, relatedPodcasts }
}
