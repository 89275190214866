import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Info = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5194 20.6171C12.3878 20.8166 11.228 20.7913 10.1062 20.5426C8.98433 20.2939 7.92247 19.8267 6.98121 19.1676C6.03995 18.5085 5.23772 17.6705 4.62033 16.7014C4.00293 15.7323 3.58247 14.651 3.38293 13.5194C3.1834 12.3878 3.20871 11.228 3.45741 10.1062C3.70611 8.98433 4.17334 7.92247 4.83242 6.98121C5.4915 6.03995 6.32951 5.23772 7.29863 4.62033C8.26774 4.00293 9.34897 3.58247 10.4806 3.38293C11.6122 3.1834 12.772 3.20871 13.8938 3.45741C15.0157 3.70611 16.0775 4.17334 17.0188 4.83242C17.9601 5.4915 18.7623 6.32951 19.3797 7.29863C19.9971 8.26774 20.4175 9.34897 20.6171 10.4806C20.8166 11.6122 20.7913 12.772 20.5426 13.8938C20.2939 15.0157 19.8267 16.0775 19.1676 17.0188C18.5085 17.9601 17.6705 18.7623 16.7014 19.3797C15.7323 19.9971 14.651 20.4175 13.5194 20.6171L13.5194 20.6171Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M12 11L12 17" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M12 8L12 7" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
