import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodySmall } from './styles/Typography'
import { Describe, Paper, Stethoscope, User } from './icons'

type Props = {
  isMobileDevice: boolean
  text: string
  index: number
  listLength: number
}

const iconZize = theme.fontSize.xxlarge
const iconColor = theme.color.plumMid

const listScreenItemsIcons = [
  <User size={30} key="User" color={iconColor} />,
  <Paper size={iconZize} key="Paper" color={iconColor} />,
  <Describe size={iconZize} key="describe3" color={iconColor} />,
  <Stethoscope size={iconZize} key="describe4" color={iconColor} />,
]

export const ScreenItem = ({ isMobileDevice, text, index, listLength }: Props) => {
  return (
    <ScreenItemContainer $index={index}>
      <FlexWrapper>
        <StyledCircle>{listScreenItemsIcons[index]}</StyledCircle>
        <ScreenItemText>{text}</ScreenItemText>
      </FlexWrapper>
      {index !== listLength - 1 && (
        <Path viewBox="0 0 264 219" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          {isMobileDevice ? (
            <path strokeWidth="3" d="M1 -32V283" stroke="white" />
          ) : (
            <path
              strokeWidth="3"
              d="M1 -32V0C1 51.9912 43.1472 94.1385 95.1384 94.1385H187C228.974 94.1385 263 128.165 263 170.138V283"
              stroke="white"
            />
          )}
        </Path>
      )}
    </ScreenItemContainer>
  )
}

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ScreenItemContainer = styled.div<{ $index: number }>`
  display: flex;
  flex-direction: column;
  color: ${theme.color.white};
  margin-left: ${({ $index }) => `${$index * 77}px`};
  position: relative;

  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    margin-left: 0;
  }
`

const StyledCircle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  min-height: 64px;
  background-color: ${theme.color.white};
  border-radius: 50%;
  margin-right: ${theme.spacing.medium}px;
`

const ScreenItemText = styled(BodySmall)`
  width: 100%;
  max-width: 360px;
  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    max-width: 460px;
  }
`
const Path = styled.svg`
  width: 80px;
  height: 48px;
  margin-left: 31px;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    margin-top: -13px;
    margin-bottom: -13px;
    height: 60px;
  }
`
