import { createGlobalStyle } from 'styled-components'
import CappellettoGrotesk from '../../assets/fonts/CappellettoGrotesk.otf'
import NeueMontrealBold from '../../assets/fonts/NeueMontreal-Bold.otf'
import NeueMontrealBoldItalic from '../../assets/fonts/NeueMontreal-BoldItalic.otf'
import NeueMontrealBook from '../../assets/fonts/NeueMontreal-Book.otf'
import NeueMontrealItalic from '../../assets/fonts/NeueMontreal-Italic.otf'
import NeueMontrealMedium from '../../assets/fonts/NeueMontreal-Medium.otf'
import NeueMontrealThin from '../../assets/fonts/NeueMontreal-Thin.otf'
import { theme } from '../../theme/theme'

const GlobalStyle = createGlobalStyle`
  .grecaptcha-badge { visibility: hidden; }

  html,
  body {
    padding: 0;
    margin: 0;
    color: ${theme.color.black};
    text-rendering: geometricPrecision ;
    overscroll-behavior: none;
    background-color: ${theme.color.beigeLight};

    /* Hide scrollbar for Chrome, Safari and Opera */
    *::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  * {
    box-sizing: border-box;
  }

   h1, h2 ,h3, h4, h5, h6, p {
    margin: 0;
  }

  b {
    font-weight: 500;
  }

  a {
    color: inherit;
    text-decoration: none;
    overflow-wrap: anywhere;

    &:hover {
      cursor:pointer;
    }
  }

  ul {
    list-style-type: square;
  }

  ul, ol {
    padding-left: ${theme.spacing.mediumLarge}px;

    p {
      margin-bottom: ${theme.spacing.tiny}px;
    }
  }

  button {
    text-rendering: geometricPrecision;
    font-family: ${theme.font.body};
  }

  textarea{
    width: 100%;
    background: #ffffff;
    border-radius: ${theme.spacing.xsmall}px;
    padding: ${theme.spacing.medium}px ${theme.spacing.xsmall}px;
    border: 1px solid transparent;
    font-family: ${theme.font.body};
    outline: 0;
    
      &:focus {
        border: 1px solid ${theme.color.plum};
      }
    }

  input[type=checkbox] {
    background: #ffffff;
    border-radius: ${theme.spacing.xsmall}px;
    width: 24px;
    height: 24px;
  }

  
  @font-face {
    font-family: 'cappeletto-grotesk';
    src: url(${CappellettoGrotesk}) format('opentype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealThin}) format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealMedium}) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealItalic}) format('opentype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealBold}) format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealBook}) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'neue-montreal';
    src: url(${NeueMontrealBoldItalic}) format('opentype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

`

export default GlobalStyle
