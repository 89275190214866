import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Paper = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.4438 14.551L14.665 21.3332L11.332 21.9999L11.9986 18.6666L18.781 11.8844C18.9554 11.7096 19.1626 11.5709 19.3907 11.4763C19.6187 11.3817 19.8632 11.333 20.1102 11.333C20.3571 11.333 20.6016 11.3817 20.8296 11.4763C21.0577 11.5709 21.2649 11.7096 21.4393 11.8844L21.4473 11.8915C21.7994 12.2447 21.9969 12.7232 21.9962 13.222C21.9955 13.7207 21.7968 14.1988 21.4438 14.551Z"
        stroke={color}
      />
      <path
        d="M8.66201 19.3333H3.32928C2.9757 19.3333 2.63659 19.1929 2.38657 18.9428C2.13655 18.6928 1.99609 18.3536 1.99609 18V3.33333C1.99609 2.97971 2.13655 2.64057 2.38657 2.39052C2.63659 2.14048 2.9757 2 3.32928 2H16.6611C17.0147 2 17.3538 2.14048 17.6038 2.39052C17.8538 2.64057 17.9943 2.97971 17.9943 3.33333V9.33334"
        stroke={color}
      />
      <path d="M8.66211 6H13.9948" stroke={color} />
      <path d="M5.99609 10H13.9952" stroke={color} />
      <path d="M5.99609 14H12.662" stroke={color} />
    </svg>
  )
}
