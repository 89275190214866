import { Asset, Entry } from 'contentful'
import { isArticle, isPodcast, isGeneralPage } from '@estercare/ester-shared'
import { theme } from '../theme/theme'
import { icons } from '../views/componentLibrary/Icons'
import { cloneElement } from 'react'

export const parseContenfulImageUrl = (asset: Asset): string => {
  return asset?.fields.file ? `https:${asset.fields.file.url}` : ''
}

export const findPathByEntry = (entry: Entry<unknown>): string => {
  if (isArticle(entry)) return `/kunskapsbank/artiklar/${entry.fields.slug}`
  if (isPodcast(entry)) return `/kunskapsbank/podcasts/${entry.fields.slug}`
  if (isGeneralPage(entry)) return `/${entry.fields.slug}`
  return ''
}

export const getBackgroundColor = (backgroundColor?: string) => {
  switch (backgroundColor) {
    case 'plum':
      return theme.color.plum
    case 'beigeDark':
      return theme.color.beigeDark
    case 'beigeLight':
      return theme.color.beigeLight
    case 'white':
      return theme.color.white
    default:
      return theme.color.white
  }
}

export const hasBlockBackgroundColorProperty = (block: unknown): string | false => {
  if (typeof block === 'object' && block !== null && 'fields' in block) {
    const fields = block.fields as Record<string, unknown>
    if ('backgroundColor' in fields && typeof fields.backgroundColor === 'string') {
      return fields.backgroundColor
    }
  }
  return false
}

export const getIcon = (icon: string, size: number, color: string): JSX.Element | null => {
  const FoundIcon = icons.find((i) => i.name === icon)?.component
  return FoundIcon ? cloneElement(FoundIcon, { size, color }) : null
}
