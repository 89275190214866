import { theme } from '../theme/theme'

export const isMobile = (width: number): boolean => {
  return width < theme.breakpoint.small
}

export const isTablet = (width: number): boolean => {
  return width >= theme.breakpoint.small && width < theme.breakpoint.large
}

export const isLowerThenCustomBreakpoint = (width: number, breakpoint: number): boolean => {
  return width < breakpoint
}

export const isDesktop = (width: number): boolean => {
  return width >= theme.breakpoint.large
}
