import { red } from '@mui/material/colors'
import { ValueOf } from '../utils/utilTypes'

export const color = {
  plum: '#5B1934',
  plumMid: '#794FB7',
  plumlight: '#F7F2F4',
  brightBlue: '#4F56CB',
  orange: '#EF643A',
  beigeDark: '#EDE7E1',
  beige: '#F8F0E8',
  beigeLight: '#F6F3F0',
  white: '#FFFFFF',
  grey1: '#F2F2F2',
  grey2: '#EBEBEB',
  grey3: '#B5B5B5',
  grey4: '#8D8D8D',
  grey5: '#5E5E5E',
  black: '#222222',
  green: '#4DBA99',
  greenLight: '#e5faf3',
  greenDark: '#146850',
  yellow: '#FFC83C',
  yellowLight: '#FAF4E5',
  red: '#B80000',
  redLight: red[50],
  redDark: red[900],
  transparent: 'transparent',
  modalBackdrop: '#00000099',
}
export type ThemeColor = ValueOf<typeof color>
