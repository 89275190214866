import { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { Close } from '../icons'

export const ModalCloseButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <StyledCloseIcon onClick={onClick} width={24} height={24} color={theme.color.black} />
)

const StyledCloseIcon = styled(Close)`
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
`
