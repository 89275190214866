export const spacing = {
  tiny: 4,
  xsmall: 8,
  small: 12,
  medium: 16,
  mediumLarge: 24,
  large: 32,
  xlarge: 36,
  xxlarge: 48,
  jumbo: 108,
  mobileHeaderHeight: 65,
}
