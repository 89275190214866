import styled from 'styled-components'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { isMobile, isTablet } from '../utils/devices'
import { VideoBlock } from './VideoBlock'
import { IImageBlockFields } from '@estercare/ester-shared'

export const ImageBlock = ({ altText, image, imageMobileView, isVideo, title }: IImageBlockFields) => {
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width) || isTablet(width)
  const src = image?.fields.file.url
  const srcMobile = imageMobileView?.fields.file.url
  if (isVideo && image) {
    return <VideoBlock video={image} title={title || ''} />
  } else {
    return (
      <ImageWrapper>
        <img alt={altText} src={isMobileDevice ? srcMobile || src : src} />
      </ImageWrapper>
    )
  }
}

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 480px;
  margin-top: 50px;
`
