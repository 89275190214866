import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.plumMid

interface ArrowProps extends IconProps {
  left?: boolean
}

export const Arrow = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, left = false, ...rest }: ArrowProps) => (
  <svg
    width={size}
    height={size}
    viewBox="-10 -10 20 20"
    preserveAspectRatio="xMidYMid meet"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d={left ? 'M8 0H-8' : 'M-8 0H8'}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d={left ? 'M-4 -4L-8 0L-4 4' : 'M4 -4L8 0L4 4'}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
