import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const FilledCheckbox = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 58 58" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 57.5C44.7401 57.5 57.5 44.7401 57.5 29C57.5 13.2599 44.7401 0.5 29 0.5C13.2599 0.5 0.5 13.2599 0.5 29C0.5 44.7401 13.2599 57.5 29 57.5ZM42.4349 20.1402C42.7885 19.7159 42.7311 19.0853 42.3069 18.7318C41.8826 18.3782 41.252 18.4355 40.8984 18.8598L26.4672 36.1774C26.1204 36.5934 25.9255 36.8238 25.7699 36.9653L25.764 36.9707L25.7576 36.9659C25.5898 36.839 25.3748 36.6272 24.9918 36.2443L17.0404 28.2929C16.6499 27.9024 16.0168 27.9024 15.6262 28.2929C15.2357 28.6834 15.2357 29.3166 15.6262 29.7071L23.5776 37.6585L23.6184 37.6993C23.9449 38.0259 24.259 38.3402 24.5513 38.5611C24.8786 38.8087 25.3074 39.0415 25.8568 39.0165C26.4062 38.9916 26.8121 38.721 27.1157 38.4448C27.3867 38.1983 27.671 37.8569 27.9666 37.5021L27.9666 37.5021L28.0036 37.4577L42.4349 20.1402Z"
        fill="#4DBA99"
      />
    </svg>
  )
}
