import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const InvoiceBlank = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M18.8 19.65C18.8 20.008 18.6578 20.3514 18.4046 20.6046C18.1514 20.8578 17.808 21 17.45 21H2.6C2.24196 21 1.89858 20.8578 1.64541 20.6046C1.39223 20.3514 1.25 20.008 1.25 19.65V2.1C1.25 1.74196 1.39223 1.39858 1.64541 1.14541C1.89858 0.892232 2.24196 0.75 2.6 0.75H12.1661C12.5239 0.750076 12.867 0.892184 13.1201 1.1451L18.4049 6.4299C18.6578 6.68298 18.7999 7.02611 18.8 7.3839V19.65Z"
          stroke={color}
        />
        <path
          id="Vector_2"
          d="M18.7998 7.5H13.3998C13.0418 7.5 12.6984 7.35777 12.4452 7.10459C12.192 6.85142 12.0498 6.50804 12.0498 6.15V0.75"
          stroke={color}
        />
      </g>
    </svg>
  )
}
