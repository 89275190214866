import styled from 'styled-components'
import { fontSize } from '../../theme/fontSize'
import { theme } from '../../theme/theme'

export const Display = styled.h1`
  font-size: 25vw;
  line-height: 1.1;
  letter-spacing: -0.2px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (min-width: ${theme.breakpoint.small}px) {
    font-size: 12vw;
    line-height: 1.1;
    letter-spacing: -0.2px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
  @media screen and (min-width: ${theme.breakpoint.xxlarge}px) {
    font-size: 7vw;
    line-height: 1.1;
    letter-spacing: -0.2px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const Title1 = styled.h1`
  font-size: ${theme.fontSize.jumbo}px;
  line-height: 52px;
  letter-spacing: -0.1px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.xlarge}px;
    line-height: 38px;
    letter-spacing: -0.1px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const Title2 = styled.h2`
  font-size: ${theme.fontSize.xxlarge}px;
  line-height: 44px;
  letter-spacing: 0px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.xlarge}px;
    line-height: 38px;
    letter-spacing: 0px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const Title3 = styled.h3`
  font-size: ${theme.fontSize.xlarge}px;
  line-height: 38px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.large}px;
    line-height: 30px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const Title4 = styled.h4`
  font-size: ${theme.fontSize.large}px;
  line-height: 30px;
  letter-spacing: 0px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.medium}px;
    line-height: 26px;
    letter-spacing: 0px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const Title5 = styled.h5`
  font-size: ${theme.fontSize.medium}px;
  line-height: 26px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.default}px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.body};
    font-weight: 500;
  }
`

export const Title6 = styled.h6`
  font-size: ${theme.fontSize.default}px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.body};
  font-weight: 500;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.default}px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.body};
    font-weight: 500;
  }
`

export const BodyXl = styled.p`
  font-size: ${theme.fontSize.mediumLarge}px;
  line-height: 28px;
  letter-spacing: 0.4px;
  font-family: ${theme.font.body};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.medium}px;
    line-height: 26px;
    letter-spacing: 0.4px;
    font-family: ${theme.font.body};
    font-weight: 400;
  }
`

export const BodyXlMedium = styled(BodyXl)`
  font-weight: 500;
`

export const BodyLarge = styled.p`
  font-size: ${theme.fontSize.default}px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.body};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.default}px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.body};
    font-weight: 400;
  }
`

export const BodyLargeMedium = styled(BodyLarge)`
  font-weight: 500;
`

export const BodyLargeMediumItalic = styled(BodyLarge)`
  font-weight: 600;
  font-style: italic;
`

export const BodyMedium = styled.p`
  font-size: ${theme.fontSize.default}px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.body};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.small}px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.body};
    font-weight: 400;
  }
`

export const BodySmall = styled.p`
  font-size: ${theme.fontSize.small}px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.body};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.small}px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.body};
    font-weight: 400;
  }
`

export const BodySmallMedium = styled(BodySmall)`
  font-weight: 500;
`

export const BodySmallMediumItalic = styled(BodySmall)`
  font-weight: 600;
  font-style: italic;
`

export const BodyTiny = styled.p`
  font-size: ${theme.fontSize.xsmall}px;
  line-height: 16px;
  letter-spacing: 0.3px;
  font-family: ${theme.font.body};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.xsmall}px;
    line-height: 16px;
    letter-spacing: 0.3px;
    font-family: ${theme.font.body};
    font-weight: 400;
  }
`

export const BodyTinyMedium = styled(BodyTiny)`
  font-weight: 500;
`

export const BodyTinyMediumItalic = styled(BodyTiny)`
  font-weight: 600;
  font-style: italic;
`

export const SmallNavigation = styled.p`
  font-size: ${theme.fontSize.xxsmall}px;
  line-height: 16px;
  letter-spacing: 0.4px;
  font-family: ${theme.font.body};
  font-weight: 500;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.xxsmall}px;
    line-height: 16px;
    letter-spacing: 0.4px;
    font-family: ${theme.font.body};
    font-weight: 500;
  }
`

export const Label = styled.span`
  font-size: ${theme.fontSize.xxsmall}px;
  line-height: 16px;
  letter-spacing: 0.6px;
  font-family: ${theme.font.body};
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.xxsmall}px;
    line-height: 16px;
    letter-spacing: 0.6px;
    font-family: ${theme.font.body};
    font-weight: 500;
    text-transform: uppercase;
  }
`

export const MenuItem = styled.li`
  font-size: ${theme.fontSize.small}px;
  line-height: 22px;
  letter-spacing: 0.1px;
  font-family: ${theme.font.heading};
  font-weight: 400;

  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-size: ${theme.fontSize.medium}px;
    line-height: 26px;
    letter-spacing: 0.1px;
    font-family: ${theme.font.heading};
    font-weight: 400;
  }
`

export const RichTextLink = styled.a`
  color: ${theme.color.plumMid};
  text-decoration: underline;
  text-decoration-color: ${theme.color.plumMid};
`

export const Blockquote = styled.blockquote`
  font-size: ${fontSize.large}px;
  text-align: center;
  margin: ${theme.spacing.xxlarge}px ${theme.spacing.medium}px;
  font-family: ${theme.font.heading};

  @media screen and (min-width: ${theme.breakpoint.small}px) {
    font-size: ${fontSize.xlarge}px;
    margin: ${theme.spacing.xlarge}px;
    line-height: 38px;
  }
`
