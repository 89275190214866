import { datadogRum } from '@datadog/browser-rum'
import { appConfig } from './config'

if (appConfig.environment === 'development' || appConfig.environment === 'vitest') {
  // eslint-disable-next-line no-console
  console.log('Not initializing Datadog in this environment: ', appConfig.environment)
} else {
  datadogRum.init({
    applicationId: appConfig.datadog.applicationId,
    clientToken: appConfig.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'b2b-web',
    env: appConfig.environment,
    version: appConfig.datadog.commitSHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
