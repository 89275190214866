import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Caret } from './icons/Caret'
import { BodySmall, Label } from './styles/Typography'

interface Props {
  subtitle: string
  href: string
  width?: string
}

export const ListenToPodcastButton: FC<Props> = ({ href, subtitle, width }) => {
  return (
    <StyledButton to={href} width={width}>
      <Row>
        <Column>
          <StyledLabel>Lyssna via Spotify</StyledLabel>
          <BodySmall className="subtitle">{subtitle.split('.')[0]}</BodySmall>
        </Column>
      </Row>
      <CaretContainer>
        <Caret />
      </CaretContainer>
    </StyledButton>
  )
}

const StyledLabel = styled(Label)`
  color: ${theme.color.plum};
`

interface StyledButtonProps {
  width?: string
}
const StyledButton = styled(Link)<StyledButtonProps>`
  ${({ width }) => width && `width: ${width};`}
  height: 100%;
  padding: ${theme.spacing.medium}px;
  background: ${theme.color.white};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 3px 9px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: ${theme.color.plumMid};
    cursor: pointer;
  }

  &:active {
    box-shadow: unset;
  }

  @media screen and (max-width: ${theme.breakpoint.small}px) {
    width: 100%;
  }
`

const Column = styled.div`
  height: 100%;
  padding-left: ${theme.spacing.medium}px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.subtitle {
      -webkit-line-clamp: 1;
    }
  }
`

const CaretContainer = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: ${theme.spacing.medium}px;
`
