import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyLargeMedium } from './styles/Typography'
import { Spinner } from './icons/Spinner'

export const LoadingCheck = (text: string, loading?: boolean) => {
  return loading ? (
    <Loading>
      <BodyLargeMedium>{text}</BodyLargeMedium> <Spinner size={15} color={theme.color.white} />
    </Loading>
  ) : (
    <BodyLargeMedium>{text}</BodyLargeMedium>
  )
}

const Loading = styled.div`
  svg {
    margin-left: ${theme.spacing.tiny}px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`
