import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const axisStyle = {
  fontSize: `${theme.fontSize.xxsmall}px`,
  fontFamily: theme.font.body,
  fill: theme.color.grey4,
  fontWeight: 500,
}

export const ChartContainer = styled.div`
  padding: ${theme.spacing.large}px;
  background-color: ${theme.color.white};
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding: ${theme.spacing.mediumLarge}px;
  }
`
export const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacing.tiny}px;
  margin-bottom: ${theme.spacing.small}px;

  & > p:first-child {
    white-space: nowrap;
    margin-right: 10px;
    @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
      white-space: break-spaces;
      margin-right: 0px;
    }
  }

  @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`
