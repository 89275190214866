import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodySmall, Label, Title6 } from './styles/Typography'
import { Link } from 'react-router-dom'
import { Asset } from 'contentful'

interface Props {
  slug: string
  title: string
  abstract: string | ReactNode
  episodeNumber?: number
  label: string
  image: Asset
  horizontal?: boolean
}

export const CollectionCard: FC<Props> = ({ slug, title, abstract, label, image, horizontal = false }) => {
  return (
    <Card to={slug} $horizontal={horizontal}>
      <ImageContainer $horizontal={horizontal}>
        <img src={image.fields.file.url} alt={image.fields.description} />
      </ImageContainer>
      <TextContainer>
        <OverflowHidden>
          {label && <Label>{label}</Label>}
          <StyledTitle6>{title}</StyledTitle6>
          <Text $horizontal={horizontal} $title={title}>
            {Array.isArray(abstract) ? abstract[0] : <BodySmall>{abstract}</BodySmall>}
          </Text>
        </OverflowHidden>
      </TextContainer>
    </Card>
  )
}

const StyledTitle6 = styled(Title6)`
  overflow: hidden;
  margin: ${theme.spacing.xsmall}px 0 ${theme.spacing.xsmall}px 0;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    font-weight: 500;
    margin: ${theme.spacing.tiny}px 0 ${theme.spacing.tiny}px 0;
  }
`
const Text = styled.div<{ $horizontal: boolean; $title: string }>`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ $horizontal, $title }) => (!$horizontal && $title.length > 50 ? 2 : 3)};
  overflow: hidden;
  text-overflow: ellipsis;
`

const OverflowHidden = styled.div`
  height: 100%;
  overflow: hidden;
`

const TextContainer = styled.div`
  width: 100%;
  padding: ${theme.spacing.large}px;
  padding-bottom: 0px;
  border-radius: ${theme.spacing.medium}px;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${theme.color.white} 100%);
    border-bottom-left-radius: ${theme.spacing.medium}px;
    border-bottom-right-radius: ${theme.spacing.medium}px;
  }

  strong {
    display: block;
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`
const ImageContainer = styled.div<{ $horizontal: boolean }>`
  z-index: 10;
  width: ${({ $horizontal }) => ($horizontal ? '90% ' : '100%')};
  height: ${({ $horizontal }) => ($horizontal ? '100% ' : '55%')};
  aspect-ratio: 1 / 1;
  flex-shrink: ${({ $horizontal }) => !$horizontal && '0'};
  overflow: hidden;
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${({ $horizontal }) => ($horizontal ? 'center' : 'top')};
  }
`
const Card = styled(Link)<{ $horizontal: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: ${({ $horizontal }) => ($horizontal ? 'row' : 'column')};
  position: relative;

  &::after {
    // Overlay effect on hover
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 16px;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 0.1;
    }
  }
`
