import { FC } from 'react'
import styled from 'styled-components'
import { parseContenfulImageUrl } from '../contentful/utils'
import { DefaultImage } from './icons/DefaultImage'
import { Asset } from 'contentful'

interface Props {
  asset?: Asset
  backgroundImage?: boolean
  contain?: boolean
  fill?: boolean
  width?: number
  height?: number
}

export const SuperImage: FC<Props> = ({ asset, backgroundImage, contain, width, height, fill }) => {
  const imageAlt = asset?.fields.description || asset?.fields.title || 'EsterCare'

  return asset ? (
    <StyledImage
      width={width}
      height={height}
      alt={imageAlt}
      src={parseContenfulImageUrl(asset)}
      {...(fill && { className: `${backgroundImage ? 'backgroundImage' : ''} ${contain ? 'contain' : ''}` })}
    />
  ) : (
    <DefaultImage width={width} />
  )
}

const StyledImage = styled.img<{ backgroundImage?: boolean }>`
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  max-height: 100%;

  &.backgroundImage {
    z-index: -1;
  }

  &.contain {
    object-fit: contain;
  }
`
