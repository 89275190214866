import { isIFAQ, ScreeningCodeStatus } from '@estercare/ester-shared'
import { useState } from 'react'
import styled from 'styled-components'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import { AccessCode } from '../../../api/ester-b2b-api/private/users/types'
import { useGetUserAccessCodeQuery } from '../../../api/ester-b2b-api/private/users/userQueries'
import { FAQ } from '../../../components/Faq'
import { Header } from '../../../components/Header'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { Title6 } from '../../../components/styles/Typography'
import { theme } from '../../../theme/theme'
import { QRCode } from '../../../components/icons/QRCode'
import { ServiceGuide } from '../../../components/ServiceGuide'

export const Screening = () => {
  const [accessCode, setAccessCode] = useState<AccessCode | undefined>()

  const { data: screeningPage, isLoading: screeningPageLoading } = useGetGeneralPage('screening')
  const { data, isFetching } = useGetUserAccessCodeQuery()

  if (screeningPageLoading) return <LoadingSpinner />
  const screeningPageContent = screeningPage?.items?.[0]?.fields

  !accessCode && !isFetching && data?.code && setAccessCode({ code: data?.code, status: data?.status })

  if (!screeningPageContent) return <PreviewGuard />
  const { title, description, contentBlocks } = screeningPageContent
  if (!title) return <PreviewGuard />

  const code = accessCode?.code || '------'
  const status = accessCode?.status
  const completed = status === ScreeningCodeStatus.CLAIMED || status === ScreeningCodeStatus.COMPLETE

  const steps = [
    {
      title: 'Ladda ner vår app',
      description:
        'Sök på “EsterCare” i App Store eller Google Play eller skanna QR-koden för att ladda ner appen direkt:',
      content: <QRCode />,
    },
    {
      title: 'Logga in',
      description: 'Du loggar enkelt in i vår app med ditt BankID',
    },
    {
      title: 'Aktivera din screening',
      description:
        'Klicka på "Profil" i menyn i appen och välj "Screeningtjänst". Aktivera sedan screeningen med din 6-siffriga kod:',
      content: (
        <CodeContainer>
          <CodeText>{code}</CodeText>
        </CodeContainer>
      ),
    },
  ]

  const claimedOrCompleteText =
    status === ScreeningCodeStatus.CLAIMED
      ? {
          title: 'Din kod har aktiverats',
          description: 'När du genomfört din screening kommer du få ditt resultat inom 3 vardagar.',
        }
      : {
          title: 'Du har genomfört din screening',
          description: 'Dina resultat färdigställs inom 3 vardagar och du får återkoppling via mail.',
        }

  return (
    <>
      <Header
        title={title}
        description={description}
        backButtonLink="/portal/halsotjanster"
        backgroundColor={theme.color.beigeLight}
      />
      <ComponentLayout paddingTop={0}>
        <StyledTitle6>Hur genomför jag screeningen?</StyledTitle6>
        <ServiceGuide steps={steps} completed={completed} completedText={claimedOrCompleteText} />
      </ComponentLayout>
      <ComponentLayout backgroundColor={theme.color.beigeLight}>
        {contentBlocks &&
          contentBlocks.map((block, index) => isIFAQ(block) && <FAQ showSupportLink key={index} {...block.fields} />)}
      </ComponentLayout>
    </>
  )
}

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const CodeContainer = styled.div`
  display: flex;
  background-color: ${theme.color.plumMid};
  border-radius: ${theme.spacing.medium}px;
  padding: ${theme.spacing.mediumLarge}px;
  align-items: center;
  justify-content: center;
`
const CodeText = styled.p`
  font-family: ${theme.font.heading};
  font-size: ${theme.fontSize.xlarge}px;
  letter-spacing: 10px;
  margin-right: -10px;
`
