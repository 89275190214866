import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import { BodySmall } from './styles/Typography'
import { useState } from 'react'
import { theme } from '../theme/theme'

type Props = {
  children: React.ReactNode
  menuItems: {
    label: string
    onClick: () => void
    disabled?: boolean
  }[]
} & Omit<MenuProps, 'open'>

export const MenuDropdown = ({ children, menuItems, ...rest }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)}>
        {children}
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...rest}
      >
        {menuItems
          .filter(({ disabled }) => !disabled)
          .map(({ label, onClick }) => (
            <StyledMenuItem
              key={label}
              onClick={() => {
                onClick()
                handleClose()
              }}
              disableRipple
            >
              <StyledBodySmall>{label}</StyledBodySmall>
            </StyledMenuItem>
          ))}
      </StyledMenu>
    </>
  )
}

const StyledMenu = styled(Menu)`
  && {
    z-index: 100000;
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: ${theme.spacing.xsmall}px ${theme.spacing.small}px;
    &:hover {
      background-color: ${theme.color.beigeLight};
    }
  }
`

const StyledBodySmall = styled(BodySmall)`
  margin: 0;
`
