import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { ViewLayout } from './ViewLayout'

export const ComponentLibraryBaseLayout: FC = () => {
  return (
    <ViewLayout>
      <Outlet />
    </ViewLayout>
  )
}
