import { theme } from '../../theme/theme'
import { IconProps } from './types'
const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.plum

export const Lock = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.2273 10.2002H6.77273C6.06982 10.2002 5.5 10.7375 5.5 11.4002V19.8002C5.5 20.4629 6.06982 21.0002 6.77273 21.0002H18.2273C18.9302 21.0002 19.5 20.4629 19.5 19.8002V11.4002C19.5 10.7375 18.9302 10.2002 18.2273 10.2002Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.04688 10.2V7.2C8.04688 6.08609 8.51619 5.0178 9.35158 4.23015C10.187 3.4425 11.32 3 12.5014 3C13.6828 3 14.8159 3.4425 15.6513 4.23015C16.4867 5.0178 16.956 6.08609 16.956 7.2V10.2"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.5 14.4004V16.8004" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
