import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Invoice = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8 20.65C20.8 21.008 20.6578 21.3514 20.4046 21.6046C20.1514 21.8578 19.808 22 19.45 22H4.6C4.24196 22 3.89858 21.8578 3.64541 21.6046C3.39223 21.3514 3.25 21.008 3.25 20.65V3.1C3.25 2.74196 3.39223 2.39858 3.64541 2.14541C3.89858 1.89223 4.24196 1.75 4.6 1.75H14.1661C14.5239 1.75008 14.867 1.89218 15.1201 2.1451L20.4049 7.4299C20.6578 7.68298 20.7999 8.02611 20.8 8.3839V20.65Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.8 8.5H15.4C15.042 8.5 14.6986 8.35777 14.4455 8.10459C14.1923 7.85142 14.05 7.50804 14.05 7.15V1.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.805 11.5097C6.99991 11.7657 7.25367 11.9709 7.54476 12.108C7.83585 12.2451 8.1557 12.31 8.4772 12.2972C9.5023 12.2972 10.3339 11.6735 10.3339 10.9049C10.3339 10.1363 9.505 9.51346 8.4808 9.51346C7.4566 9.51346 6.625 8.88976 6.625 8.12026C6.625 7.35076 7.4566 6.72796 8.4808 6.72796C8.80233 6.71493 9.12226 6.77972 9.41339 6.91682C9.70452 7.05392 9.95824 7.25929 10.153 7.51546"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8.48071 12.2971V13.225" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.48071 5.80005V6.72795"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.0249 14.575H17.4249" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.30005 18.625H17.425" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
