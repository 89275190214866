import { useAuth0 } from '@auth0/auth0-react'
import { ViewLayout } from '../components/layouts/ViewLayout'
import { ComponentLayout } from '../components/layouts/ComponentLayout'
import { useEffect } from 'react'
import { LoadingSpinner } from '../components/LoadingSpinner'

export const CreateAccount = () => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    const initiateSignup = async () => {
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
      })
    }

    initiateSignup()
  }, [loginWithRedirect])

  return (
    <ViewLayout>
      <ComponentLayout>
        <LoadingSpinner />
      </ComponentLayout>
    </ViewLayout>
  )
}
