import { theme } from '../theme/theme'
import { BlockItem } from './BlockItem'
import { Title6 } from './styles/Typography'
import styled from 'styled-components'

import { Asset } from 'contentful'
import { VideoPlayer } from './Videoplayer'

interface props {
  video: Asset
  title: string
}

export const VideoBlock = ({ video, title }: props) => {
  return (
    <CardContainer>
      <StyledTitle6>{title}</StyledTitle6>
      <BlockItem>
        <VideoPlayer video={video} />
      </BlockItem>
    </CardContainer>
  )
}

export const CardContainer = styled.div``

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.medium}px;
`
