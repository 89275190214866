import { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Title6 } from './styles/Typography'
import { getIcon } from '../contentful/utils'
import { Link } from 'react-router-dom'

interface Props {
  url: string
  title: string
  icon?: string
}

export const SupportCard: FC<Props> = ({ url, title, icon }) => {
  return (
    <Container to={url}>
      <IconContainer>{icon && getIcon(icon, 24, theme.color.plumMid)}</IconContainer>
      <Title6>{title}</Title6>
    </Container>
  )
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${theme.color.plumlight};
  width: 40px;
  height: 40px;
  margin-bottom: ${theme.spacing.small}px;
`
const Container = styled(Link)`
  background-color: ${theme.color.white};
  height: 172px;
  min-width: 130px;
  border-radius: ${theme.spacing.medium}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${theme.spacing.small}px;
`
