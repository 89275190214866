import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FC } from 'react'
import { Document } from '@contentful/rich-text-types'
import { getOptions } from './options'
import styled from 'styled-components'
import { theme } from '../../theme/theme'

interface Props {
  richText: Document
}

export const RichText: FC<Props> = ({ richText }) => {
  return <TextContainer>{documentToReactComponents(richText, getOptions(true))}</TextContainer>
}

const TextContainer = styled.div`
  > p {
    margin-bottom: ${theme.spacing.medium}px;
  }
  blockquote > p {
    all: inherit;
  }
`
