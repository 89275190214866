/* eslint-disable no-restricted-syntax */

const required = (name: string): string => {
  const envVar = import.meta.env[name]
  if (!envVar) {
    throw new Error(`Environment variable ${name} is required`)
  }
  return envVar
}

const optional = (name: string): string | null => {
  return import.meta.env[name] || null
}

const withDefault = ({ name, defaultValue }: { name: string; defaultValue: string }): string => {
  return import.meta.env[name] || defaultValue
}

const requiredUnlessTesting = (name: string, fallback?: string): string | undefined => {
  if (process.env.NODE_ENV === 'vitest') {
    return fallback
  }
  return required(name)
}

const isProd = (name: string): boolean => {
  return import.meta.env[name] === 'production'
}

const isDev = (): boolean => {
  return import.meta.env.MODE === 'development'
}

const useDeliveryApi = Boolean(import.meta.env.VITE_USE_CONTENTFUL_DELIVERY_API || false)

export const env = {
  required,
  optional,
  withDefault,
  requiredUnlessTesting,
  isProd,
  isDev,
  useDeliveryApi,
}
