import styled from 'styled-components'
import { Grid } from '../../../components/Grid'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { BodyLarge, Title2 } from '../../../components/styles/Typography'
import { theme } from '../../../theme/theme'
import { TrainingCard } from '../../../components/TrainingCard'
import { useGetEntriesByContentType } from '../../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { useGetUserTrainingsQuery } from '../../../api/ester-b2b-api/private/trainings/trainingQueries'
import { TrainingStatus } from '../../../api/ester-b2b-api/private/trainings/types'
import { ITrainingFields } from '@estercare/ester-shared'

export const TrainingIndex = () => {
  const { data: trainings, isLoading: trainingsLoading } = useGetEntriesByContentType<ITrainingFields>('training')
  const { data: completedTrainings, isLoading: completedTrainingsLoading } = useGetUserTrainingsQuery()

  if (trainingsLoading || completedTrainingsLoading) return <LoadingSpinner />
  if (!trainings?.items) return <PreviewGuard />

  const isTrainingCompleted = (trainingIdentifier: string) => {
    return Array.isArray(completedTrainings)
      ? completedTrainings.some(
          (training) => training.trainingId === trainingIdentifier && training.status === TrainingStatus.COMPLETED
        )
      : false
  }

  return (
    <ComponentLayout>
      <StyledTitle2>Utbildning</StyledTitle2>
      <StyledBodyLarge>
        Utbildning inom kvinnohälsa är avgörande för att öka medvetenheten om kvinnors unika hälsobehov. Genom ökad
        kunskap kan vi minska stigmat, förbättra arbetsmiljöer och främja en mer inkluderande och hälsosam arbetsplats.
      </StyledBodyLarge>
      <Grid $columns={2}>
        {trainings.items.map(({ sys, fields: { slug, image, title, description, trainingIdentifier } }) => {
          if (!title || !description || !trainingIdentifier || !slug || !image) {
            return <PreviewGuard key={sys.id} />
          }
          return (
            <TrainingCard
              key={sys.id}
              url={slug}
              imageUrl={image.fields.file.url}
              title={title}
              description={description}
              duration={15}
              completed={isTrainingCompleted(trainingIdentifier)}
            />
          )
        })}
      </Grid>
    </ComponentLayout>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.large}px;
  max-width: 640px;
`
