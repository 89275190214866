import { useState, useMemo } from 'react'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'

import { TableSortLabel } from '@mui/material'

import { Permission, UsersFromOrganisationDb } from '../../api/ester-b2b-api/private/users/types'
import { useSelectUserId } from '../../state/selectors'
import { BodySmallMedium, BodyTiny } from '../styles/Typography'
import { MenuDropdown } from '../MenuDropdown'
import { HorizontalDots } from '../icons'
import { Table } from '../Table'
import { EmployeesTableTag } from './EmployeesTableTag'

type Props = {
  employees: UsersFromOrganisationDb[]
  onSelectedEmployee: (employee: UsersFromOrganisationDb) => void
  openDialog: () => void
  onUpdateUser: (userId: string, newPermission: Permission) => void
}

type Order = 'asc' | 'desc'
type SortableKey = 'createdAt' | 'lastLogin'

export const EmployeesTable = ({ employees, onSelectedEmployee, openDialog, onUpdateUser }: Props) => {
  const userId = useSelectUserId()
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<SortableKey>('createdAt')

  const handleRequestSort = (property: SortableKey) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const sortedEmployees = useMemo(() => {
    return [...employees].sort((a, b) => {
      const aValue = a[orderBy] ? new Date(a[orderBy]).getTime() : 0
      const bValue = b[orderBy] ? new Date(b[orderBy]).getTime() : 0
      return order === 'asc' ? aValue - bValue : bValue - aValue
    })
  }, [employees, order, orderBy])

  const tableData = useMemo(() => {
    return sortedEmployees.map((user) => {
      const isUsersRow = user.id === userId
      const isEmployeeAdmin = user.UserPermission.some(({ permission }) => permission === 'ADMIN')
      const newPermission = isEmployeeAdmin ? 'EMPLOYEE' : 'ADMIN'

      return {
        name: (
          <div>
            <BodySmallMedium>{`${user.firstName} ${user.lastName}`}</BodySmallMedium>
            <BodyTiny>{user.email}</BodyTiny>
          </div>
        ),
        tag: (
          <EmployeesTableTag
            lastLogin={user.lastLogin}
            isManager={user.isManager}
            permission={user.UserPermission.map((i) => i.permission)}
          />
        ),
        added: <BodySmallMedium>{format(new Date(user.createdAt), 'd MMM yyyy', { locale: sv })}</BodySmallMedium>,
        active: (
          <BodySmallMedium>
            {user.lastLogin ? format(new Date(user.lastLogin), 'd MMM yyyy', { locale: sv }) : ''}
          </BodySmallMedium>
        ),
        action: !isUsersRow && (
          <MenuDropdown
            menuItems={[
              {
                label: 'Ta bort användare',
                onClick: () => {
                  onSelectedEmployee(user)
                  openDialog()
                },
              },
              {
                label: isEmployeeAdmin ? 'Ta bort adminbehörighet' : 'Ge adminbehörighet',
                onClick: () => onUpdateUser(user.id, newPermission),
              },
            ]}
          >
            <HorizontalDots size={20} />
          </MenuDropdown>
        ),
      }
    })
  }, [sortedEmployees, userId, onSelectedEmployee, openDialog, onUpdateUser])

  const headers = {
    name: 'Namn',
    tag: '',
    added: (
      <TableSortLabel
        active={orderBy === 'createdAt'}
        direction={orderBy === 'createdAt' ? order : 'asc'}
        onClick={() => handleRequestSort('createdAt')}
      >
        Tillagd
      </TableSortLabel>
    ),
    active: (
      <TableSortLabel
        active={orderBy === 'lastLogin'}
        direction={orderBy === 'lastLogin' ? order : 'asc'}
        onClick={() => handleRequestSort('lastLogin')}
      >
        Senast inloggad
      </TableSortLabel>
    ),
    action: '',
  }

  return <Table data={tableData} headers={headers} />
}
