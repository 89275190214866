import { IconProps } from './types'

export const Play = ({ height, width, color = '#fff' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_82_278)">
      <path
        d="M16 0C7.1635 0 0 7.1635 0 16C0 24.8365 7.1635 32 16 32C24.8371 32 32 24.8365 32 16C32 7.1635 24.8371 0 16 0ZM16 28.8C8.94206 28.8 3.2 23.0579 3.2 16C3.2 8.94206 8.94206 3.2 16 3.2C23.0579 3.2 28.8 8.94206 28.8 16C28.8 23.0579 23.0579 28.8 16 28.8Z"
        fill={color}
      />
      <path
        d="M21.8193 14.88L14.0599 10.4C12.9931 9.78429 12.1201 10.288 12.1201 11.52V20.48C12.1201 21.712 12.9931 22.2163 14.0599 21.6L21.8193 17.12C22.8862 16.5043 22.8862 15.4957 21.8193 14.88Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_82_278">
        <rect width={width} height={height} fill={color} />
      </clipPath>
    </defs>
  </svg>
)
