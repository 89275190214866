import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Caret } from './icons/Caret'
import { BodyLarge, BodyTiny } from './styles/Typography'

interface Props {
  title: string
  subtitle: string
  number: string
  href: string
}

export const PodcastButton: FC<Props> = ({ title, href, subtitle, number }) => {
  return (
    <StyledButton to={href}>
      <Row>
        <Circle>
          <NumberTypography $numberLength={number.length}>{number}</NumberTypography>
        </Circle>
        <Column>
          <BodyLarge>{title}</BodyLarge>
          <StyledBodyTiny className="subtitle">{subtitle.split('.')[0]}</StyledBodyTiny>
        </Column>
      </Row>
      <CaretContainer>
        <Caret />
      </CaretContainer>
    </StyledButton>
  )
}

const StyledButton = styled(Link)`
  height: 100%;
  padding: ${theme.spacing.medium}px;
  background: ${theme.color.white};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 3px 9px rgba(0, 0, 0, 0.05);

  &:hover {
    border-color: ${theme.color.plumMid};
    cursor: pointer;
  }

  &:active {
    box-shadow: unset;
  }
`

const calculateDimension = (length: number) => `${length === 1 ? 1.8 : length - 0.2}em`
const calculateLineHeight = (length: number) => `${length === 1 ? 2 : length}em`
const calculateFontSize = (length: number, baseFontSize: number) =>
  length === 1 ? `${baseFontSize}px` : `${(baseFontSize / length) * 2}px`

const NumberTypography = styled.span<{ $numberLength: number }>`
  color: ${theme.color.plumMid};
  font-family: ${theme.font.heading};
  height: ${({ $numberLength }) => calculateDimension($numberLength)};
  width: ${({ $numberLength }) => calculateDimension($numberLength)};
  line-height: ${({ $numberLength }) => calculateLineHeight($numberLength)};
  display: block;
  font-size: ${({ $numberLength }) => calculateFontSize($numberLength, theme.fontSize.xlarge)};
  text-align: center;

  @media screen and (min-width: ${theme.breakpoint.small}px) {
    font-size: ${({ $numberLength }) => calculateFontSize($numberLength, theme.fontSize.xxlarge)};
  }
`

const StyledBodyTiny = styled(BodyTiny)`
  color: ${theme.color.grey5};
`

const Circle = styled.div`
  border-radius: 50%;
  background-color: ${theme.color.plumlight};
`

const Column = styled.div`
  height: 100%;
  padding-left: ${theme.spacing.medium}px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.subtitle {
      -webkit-line-clamp: 1;
    }
  }
`

const CaretContainer = styled.div`
  display: flex;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: ${theme.spacing.medium}px;
`
