interface Props {
  width?: number
}
export const DefaultImage = ({ width }: Props) => {
  const defaultWidth = 500

  return (
    <svg width={width || defaultWidth} viewBox="0 0 1600 2000" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_16_138)">
        <path
          d="M886.457 294.807C886.457 294.807 514.33 323.163 460.072 513.968C405.813 704.774 483.6 854.727 524.894 899.905C566.188 945.083 563.307 956.618 556.585 1019.1C550.343 1081.58 543.62 1124.35 543.62 1124.35C543.62 1124.35 668.943 1104.65 692.471 1073.41C746.73 1002.76 660.3 908.075 610.363 792.727C560.426 677.379 567.148 700.448 567.148 700.448C567.148 700.448 747.69 636.526 783.702 615.379C819.715 594.712 909.025 477.922 909.025 477.922C909.025 477.922 942.637 550.015 970.486 585.581C998.816 620.666 1111.17 787.44 1091.01 826.37C1070.84 865.3 920.549 984.974 916.708 1004.2C912.866 1023.42 838.441 1054.66 917.188 1163.28C996.415 1271.9 1059.8 1339.67 1092.93 1327.65C1126.06 1315.64 1109.73 1225.76 1109.73 1225.76C1109.73 1225.76 1374.3 1311.79 1135.66 1073.41C1062.68 1000.35 1156.79 863.378 1167.83 758.603C1179.36 654.309 1226.89 302.016 886.457 294.807Z"
          fill="#E9CABE"
        />
        <path
          d="M307.38 1634.29C307.38 1634.29 207.986 1166.65 373.643 1145.5C539.3 1124.83 671.345 1120.99 704.476 1072.93C737.607 1025.34 700.155 979.686 671.345 909.035C642.535 838.385 443.747 459.657 840.843 413.518C1237.94 367.379 1076.6 1004.68 919.59 1023.9C919.59 1023.9 642.055 1108.01 582.034 700.928C582.034 700.928 836.521 631.719 915.268 516.37C915.268 516.37 1117.9 731.687 1093.41 826.85C1068.92 922.012 886.458 982.57 894.621 1073.41C902.784 1164.72 1010.34 1319.48 1095.33 1328.13C1180.32 1336.79 1122.22 1133.48 1095.33 1191.64C1068.44 1249.79 1192.8 1340.63 1205.29 1241.14C1217.77 1141.65 1110.21 1096.48 1122.7 992.663C1135.18 888.849 1350.3 255.395 861.97 296.728C373.643 338.061 419.739 863.857 535.458 964.787C651.178 1065.24 448.068 1145.98 660.301 1158.48"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M907.586 1025.83C907.586 1025.83 886.458 1081.1 905.665 1099.36C949.84 1140.69 1212.97 1101.28 1249.46 1128.68C1285.95 1156.07 1389.19 1347.84 1252.34 1636.21"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M469.675 1135.41C469.675 1135.41 437.504 1268.54 492.243 1367.06"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M1068.92 1122.91C1068.92 1122.91 1114.06 1368.99 964.725 1636.21"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M948.88 1711.19C977.255 1711.19 1000.26 1688.16 1000.26 1659.76C1000.26 1631.36 977.255 1608.34 948.88 1608.34C920.504 1608.34 897.502 1631.36 897.502 1659.76C897.502 1688.16 920.504 1711.19 948.88 1711.19Z"
          fill="#DF6644"
        />
        <path
          d="M550.391 1727C568.954 1727 584.002 1711.94 584.002 1693.36C584.002 1674.78 568.954 1659.71 550.391 1659.71C531.828 1659.71 516.779 1674.78 516.779 1693.36C516.779 1711.94 531.828 1727 550.391 1727Z"
          fill="#DF6644"
        />
        <path
          d="M741.928 1620.83C760.491 1620.83 775.539 1605.77 775.539 1587.19C775.539 1568.61 760.491 1553.54 741.928 1553.54C723.365 1553.54 708.316 1568.61 708.316 1587.19C708.316 1605.77 723.365 1620.83 741.928 1620.83Z"
          fill="#DF6644"
        />
        <path
          d="M547.461 1687.64C547.461 1687.64 291.054 1551.14 481.199 1377.16C671.344 1203.18 746.249 1537.2 741.928 1581.42"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M645.416 1111.38C645.416 1111.38 752.493 1355.05 918.149 1163.76"
          stroke="#DF6644"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16_138">
          <rect width="1053" height="1437" fill="white" transform="translate(273 290)" />
        </clipPath>
      </defs>
    </svg>
  )
}
