import { theme } from '../../theme/theme'
import { IconProps } from './types'
const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.plum

export const HorizontalDots = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4.5" cy="12" r="2" fill={color} />
    <circle cx="12.5" cy="12" r="2" fill={color} />
    <circle cx="20.5" cy="12" r="2" fill={color} />
  </svg>
)
