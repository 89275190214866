import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { MenuItem } from '../../utils/menus/types'
import { SidebarUserMenu } from './SidebarUserMenu'
import { MenuListItem } from './MenuListItem'

interface Props {
  menuItems: MenuItem[]
  open: boolean
}

export const MobileSideBar = ({ menuItems, open }: Props) => {
  return (
    <SidebarContainer className={`${open && 'open'}`}>
      <div>
        {menuItems.map((item, index) => {
          return <MenuListItem key={index} item={item} />
        })}
      </div>

      <SidebarUserMenu />
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div`
  top: 65px;
  position: fixed;
  background-color: ${theme.color.beigeLight};
  padding: ${theme.spacing.small}px ${theme.spacing.small}px 0 ${theme.spacing.small}px;
  width: 100%;
  height: calc(100% - 65px);
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;

  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.open {
    display: flex;
    transform: translateX(0);
    @media screen and (min-width: ${theme.breakpoint.medium}px) {
      display: none;
    }
  }
`
