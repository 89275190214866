import React, { useRef } from 'react'
import styled from 'styled-components'
import { Title3 } from './styles/Typography'
import { theme } from '../theme/theme'
import { Document } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getOptions } from './richText/options'
import { useHeightObserver } from '../hooks/useHeightObserver'

interface ImageTextBlockProps {
  imageUrl: string
  imageOrientation: 'left' | 'right'
  title: string
  text: Document
}

export const ImageTextBlock: React.FC<ImageTextBlockProps> = ({ imageUrl, imageOrientation, title, text }) => {
  const textContainer = useRef<HTMLDivElement>(null)
  const textHeight = useHeightObserver(textContainer)

  return (
    <Container $imageOrientation={imageOrientation}>
      <ImageContainer $height={textHeight}>
        <Image src={imageUrl} alt={title} />
      </ImageContainer>
      <TextContainer ref={textContainer}>
        <StyledTitle3>{title}</StyledTitle3>
        {documentToReactComponents(text, getOptions(false))}
      </TextContainer>
    </Container>
  )
}

const Container = styled.div<{ $imageOrientation: 'left' | 'right' }>`
  max-width: 1440px;
  display: flex;
  flex-direction: ${({ $imageOrientation }) => ($imageOrientation === 'right' ? 'row-reverse' : 'row')};
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.large}px) {
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  width: 50%;
  padding: ${theme.spacing.large}px;
  align-content: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.large}px) {
    width: 100%;
    padding: ${theme.spacing.mediumLarge}px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%;
`

const ImageContainer = styled.div<{ $height: number | null }>`
  width: 50%;
  min-height: 560px;
  max-height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.large}px) {
    width: 100%;
    height: 460px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    height: 380px;
  }
`

const StyledTitle3 = styled(Title3)`
  margin-bottom: ${theme.spacing.medium}px;
`
