import styled from 'styled-components'
import { WaveCenter } from './WaveCenter'
import { WaveEnd } from './WaveEnd'
import { WaveStart } from './WaveStart'

export const Wave = ({ color }: { color: string }) => (
  <WaveContainer>
    <WaveStart color={color} />
    <WaveCenter color={color} />
    <WaveEnd color={color} />
  </WaveContainer>
)

const WaveContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
`
