import styled from 'styled-components'
import { theme } from '../theme/theme'

interface Props {
  $gridGap?: number
  $columns?: number
}

export const Grid = styled.section<Props>`
  display: grid;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-gap: ${({ $gridGap }) => $gridGap?.toString() || theme.spacing.mediumLarge}px;
  grid-template-columns: ${({ $columns }) =>
    $columns ? `repeat(${$columns}, 1fr)` : 'repeat(auto-fit, minmax(100px, 1fr))'};

  @media screen and (max-width: ${theme.breakpoint.large}px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
`
