import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const High = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6154 11.2222L12 18.9999"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2308 19C21.864 17.4635 22.1122 15.7926 21.9534 14.1358C21.7946 12.479 21.2337 10.8875 20.3205 9.50248C19.4073 8.11747 18.1701 6.98184 16.7186 6.1964C15.2671 5.41096 13.6463 5 12 5C10.3537 5 8.73291 5.41096 7.28142 6.1964C5.82994 6.98184 4.59269 8.11747 3.6795 9.50248C2.76631 10.8875 2.20542 12.479 2.04663 14.1358C1.88784 15.7926 2.13605 17.4635 2.76923 19H21.2308Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
