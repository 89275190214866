import { useEffect, useState } from 'react'

export const useHeightObserver = (elementRef: React.RefObject<HTMLDivElement> | null) => {
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setHeight(elementRef?.current ? elementRef.current.offsetHeight : 0)
    })
    elementRef?.current && resizeObserver.observe(elementRef.current)
    return () => resizeObserver.disconnect()
  }, [elementRef])

  return height
}
