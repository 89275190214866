import { useLocation } from 'react-router-dom'

export const useGetQueryParams = <T extends string>(params: T | T[]) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  if (Array.isArray(params)) {
    return params.reduce(
      (acc, param) => {
        acc[param] = searchParams.get(param) ?? undefined
        return acc
      },
      {} as Record<T, string | undefined>
    )
  }

  return {
    [params]: searchParams.get(params) ?? undefined,
  } as Record<T, string | undefined>
}
