// This component is heavily inspired by https://hudecz.medium.com/how-to-create-a-pure-css-toggle-button-2fcc955a8984
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { useNavigate, useLocation } from 'react-router-dom'
import { Label } from '../styles/Typography'

export const ToggleButton = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAdminPath = location.pathname.startsWith('/admin')

  return (
    <>
      <ToggleCheckbox
        type="checkbox"
        id="toggle"
        className="toggleCheckbox"
        checked={isAdminPath}
        onChange={() => navigate(isAdminPath ? '/portal' : '/admin')}
      />
      <ToggleContainer $checked={isAdminPath} htmlFor="toggle">
        <ToggleContent $checked={isAdminPath}>
          <Label>Admin</Label>
        </ToggleContent>
        <ToggleContent $checked={!isAdminPath}>
          <Label>Anställd</Label>
        </ToggleContent>
      </ToggleContainer>
    </>
  )
}

const ToggleContainer = styled.label<{ $checked: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  padding: 6px;
  border-radius: 50px;
  background-color: ${theme.color.beigeDark};
  font-weight: bold;
  color: #343434;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    left: ${(props) => (props.$checked ? '0%' : '50%')};
    border-radius: 50px;
    background: ${theme.color.plum};
    transition: all 0.3s;
  }
`

const ToggleCheckbox = styled.input`
  display: none;
`

const ToggleContent = styled.div<{ $checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  text-align: center;
  z-index: 1;
  color: ${(props) => (props.$checked ? theme.color.white : theme.color.black)};
  transition: color 0.3s;
`
