import { Link } from 'react-router-dom'
import styled, { CSSProperties } from 'styled-components'
import { theme } from '../../theme/theme'
import React from 'react'

export interface ButtonProps {
  buttonStyle?: CSSProperties
  secondary?: boolean
  buttonColor?: string
  loading?: boolean
  disabled?: boolean
  text: string
  icon?: React.ReactNode
  $hoverColor?: string
}

export const Button = styled(Link)<{
  $buttonColor: string
  $secondary?: boolean
  $disabled?: boolean
  $hoverColor?: string
}>`
  padding: ${theme.spacing.small}px ${theme.spacing.mediumLarge}px;
  background: ${({ $buttonColor, $secondary }) => ($secondary ? theme.color.transparent : `${$buttonColor}`)};
  border: 1px solid ${({ $buttonColor }) => $buttonColor};
  border-radius: 50px;
  color: ${({ $buttonColor, $secondary }) => ($secondary ? `${$buttonColor}` : theme.color.white)};
  text-align: center;
  font-weight: 500;
  display: inline-block;
  min-width: 100px;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  font-family: ${theme.font.body};
  font-size: ${theme.fontSize.default}px;
  line-height: 24px;
  text-rendering: geometricPrecision;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.tiny}px;

  &:hover {
    background: ${({ $buttonColor, $secondary }) =>
      $secondary
        ? `${$buttonColor}`
        : $buttonColor === theme.color.orange
          ? theme.color.transparent
          : theme.color.orange};
    border-color: ${({ $buttonColor, $secondary }) => ($secondary ? `${$buttonColor}` : theme.color.orange)};
    color: ${({ $buttonColor }) =>
      $buttonColor === theme.color.orange
        ? theme.color.orange
        : $buttonColor === theme.color.white
          ? theme.color.plum
          : theme.color.white};
    cursor: pointer;
  }
  &:disabled {
    background: ${theme.color.grey2};
    border-color: ${theme.color.grey2};
    color: ${theme.color.grey3};
    cursor: not-allowed;
  }
`
