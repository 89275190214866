import React from 'react'
import styled from 'styled-components'
import { Input } from '../Input'
import { SelectInput } from '../SelectInput'
import { BodySmall } from '../styles/Typography'
import { theme } from '../../theme/theme'
import { SelectOption } from './types'

export const BirthYearInput: React.FC<{
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}> = ({ value, onChange, error }) => (
  <InputWrapper>
    <StyledInput label="Födelseår" placeholder="YYYY" value={value} onChange={onChange} maxLength={4} />
    {error && <StyledErrorText>{error}</StyledErrorText>}
  </InputWrapper>
)

export const GenderInput: React.FC<{
  value: string
  onChange: (value: string) => void
  options: SelectOption[]
}> = ({ value, onChange, options }) => (
  <InputWrapper>
    <SelectInput
      backgroundColor="beigeLight"
      label="Biologiskt kön"
      options={options}
      value={value}
      onChange={onChange}
      placeholder="Välj ett alternativ"
    />
  </InputWrapper>
)

export const ZipCodeInput: React.FC<{
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}> = ({ value, onChange, error }) => (
  <InputWrapper>
    <FormattedZipCodeInput label="Ditt postnummer" placeholder="123 45" value={value} onChange={onChange} />
    {error && <StyledErrorText>{error}</StyledErrorText>}
  </InputWrapper>
)

interface FormattedZipCodeInputProps {
  label: string
  placeholder: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FormattedZipCodeInput: React.FC<FormattedZipCodeInputProps> = ({ label, placeholder, value, onChange }) => {
  const formatZipCode = (val: string) => {
    const digits = val.replace(/\D/g, '').slice(0, 5)
    return digits.length > 3 ? `${digits.slice(0, 3)} ${digits.slice(3)}` : digits
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, '')
    onChange({ ...e, target: { ...e.target, value: rawValue } })
  }

  return (
    <StyledInput
      label={label}
      placeholder={placeholder}
      value={formatZipCode(value)}
      onChange={handleChange}
      maxLength={6}
      inputMode="numeric"
      pattern="[0-9]*"
    />
  )
}

const InputWrapper = styled.div`
  margin-top: ${theme.spacing.mediumLarge}px;
`

const StyledInput = styled(Input)`
  background-color: ${theme.color.beigeLight};

  span {
    text-transform: capitalize;
  }
`

const StyledErrorText = styled(BodySmall)`
  color: ${theme.color.red};
`
