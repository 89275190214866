import { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodySmallMedium } from './styles/Typography'
import { Link } from 'react-router-dom'
import { Arrow } from './icons/Arrow'

interface Props {
  text: string
  href: string
}

export const TextLink: FC<Props> = ({ text, href }) => {
  return (
    <StyledLink to={href}>
      <BodySmallMedium>{text}</BodySmallMedium>
      <Arrow />
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  color: ${theme.color.plumMid};
  font-weight: 500;
  font-size: ${theme.fontSize.small}px;
  display: inline-flex;
  align-items: center;

  span {
    margin-right: ${theme.spacing.tiny}px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
