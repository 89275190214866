import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Stethoscope = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.9238 16.5393C20.5807 16.5393 21.9238 15.1962 21.9238 13.5393C21.9238 11.8825 20.5807 10.5393 18.9238 10.5393C17.267 10.5393 15.9238 11.8825 15.9238 13.5393C15.9238 15.1962 17.267 16.5393 18.9238 16.5393Z"
      stroke={color}
    />
    <path
      d="M5.03797 1.95386C4.23225 1.95386 3.45953 2.25537 2.8898 2.79206C2.32007 3.32874 2 4.05665 2 4.81564V8.63136C2 9.89635 2.53345 11.1095 3.483 12.004C4.43255 12.8985 5.72042 13.401 7.06329 13.401C8.40616 13.401 9.69403 12.8985 10.6436 12.004C11.5931 11.1095 12.1266 9.89635 12.1266 8.63136V4.81564C12.1266 4.05665 11.8065 3.32874 11.2368 2.79206C10.667 2.25537 9.89433 1.95386 9.08861 1.95386"
      stroke={color}
    />
    <path d="M7.0625 13.4011L7.0625 17.4553C7.0625 24.8483 18.9612 24.8483 18.9612 17.4553V16.5014" stroke={color} />
    <path d="M5.03711 1V3.38482" stroke={color} />
    <path d="M9.08789 1V3.38482" stroke={color} />
  </svg>
)
