import { CreditCard } from '../../components/icons/CreditCard'
import { High } from '../../components/icons/High'
import { Home } from '../../components/icons/Home'
import { Invoice } from '../../components/icons/Invoice'
import { QuestionMark } from '../../components/icons/QuestionMark'
import { User } from '../../components/icons/User'
import { MenuItem } from './types'

export const adminMenu: MenuItem[] = [
  { name: 'Översikt', path: '/admin', icon: Home },
  { name: 'Tjänster', path: '/admin/tjanster', icon: Invoice },
  { name: 'Statistik', path: '/admin/statistik', icon: High },
  { name: 'Medarbetare', path: '/admin/medarbetare', icon: User },
  { name: 'Fakturering', path: '/admin/fakturering', icon: CreditCard },
  { name: 'Support', path: '/admin/support', icon: QuestionMark },
]
