import { SVGProps } from 'react'

interface WaveInterface extends SVGProps<SVGSVGElement> {
  color?: string
}

export const WaveCenter = ({ color = '#fff', ...rest }: WaveInterface) => (
  <svg
    width="100%"
    height="90"
    viewBox="0 0 1236 90"
    xmlns="http://www.w3.org/2000/svg"
    // this fixes weird line issues on Safari:
    // https://linear.app/estercare/issue/EST-513/bug-wave-has-visual-gaps-on-some-sizes-and-browsers
    style={{ marginLeft: -1, marginRight: -1, marginBottom: -1 }}
    {...rest}
    preserveAspectRatio="none"
  >
    <path d="M1236 0L0 6e-05V90H1236V0Z" fill={color} />
  </svg>
)
