import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 8
const DEFAULT_COLOR = theme.color.orange

export const Polygon = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size * 1.3} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.6665 6L8.6665 0H0.666504L4.6665 6Z" fill={color} />
    </svg>
  )
}
