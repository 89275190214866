import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useParams } from 'react-router-dom'
import { isIFAQ } from '@estercare/ester-shared'
import { DynamicBlock } from '../../../components/DynamicBlock'
import { Header } from '../../../components/Header'
import { getBackgroundColor } from '../../../contentful/utils'
import { theme } from '../../../theme/theme'

export const EmployeeSupport = () => {
  const slug = useParams()
  const { data: supportPage, isLoading } = useGetGeneralPage(slug?.id || '')

  if (isLoading) return <LoadingSpinner />

  const supportContent = supportPage?.items?.[0]?.fields
  if (!supportContent) return <PreviewGuard />

  const { title, description, contentBlocks } = supportContent

  if (!title || !contentBlocks) return <PreviewGuard />

  return (
    <>
      <Header
        title={title}
        backButtonLink="/portal/support"
        backgroundColor={theme.color.beigeLight}
        description={description || ''}
      />
      {Array.isArray(contentBlocks) &&
        contentBlocks.map((block, index) => {
          const backGroundColor = isIFAQ(block)
            ? getBackgroundColor(block.fields.backgroundColor)
            : theme.color.beigeLight
          return (
            <ComponentLayout key={index} paddingTop={0} backgroundColor={backGroundColor}>
              <DynamicBlock block={block} />
            </ComponentLayout>
          )
        })}
    </>
  )
}
