import styled from 'styled-components'
import { Label } from '../styles/Typography'
import { theme } from '../../theme/theme'
import { Permission } from '../../api/ester-b2b-api/private/users/types'

type Props = {
  lastLogin: string
  isManager: boolean
  permission: Permission[]
}

export const EmployeesTableTag = ({ lastLogin, isManager, permission }: Props) => {
  if (!lastLogin) {
    return (
      <InvitationPendingContainer>
        <Label>INBJUDEN</Label>
      </InvitationPendingContainer>
    )
  }

  if (permission.includes('ADMIN')) {
    return (
      <IsManagerContainer>
        <Label>ADMIN</Label>
      </IsManagerContainer>
    )
  }

  if (isManager) {
    return (
      <IsNotManagerContainer>
        <Label>PERSONALANSVAR</Label>
      </IsNotManagerContainer>
    )
  }
  return null
}

const SharedContainer = styled.div`
  display: inline-block;
  padding: 6px ${theme.spacing.xsmall}px;
  margin-left: ${theme.spacing.small}px;
  border-radius: ${theme.spacing.tiny}px;
`

const InvitationPendingContainer = styled(SharedContainer)`
  background-color: ${theme.color.yellowLight};
  color: ${theme.color.black};
`

const IsManagerContainer = styled(SharedContainer)`
  background-color: ${theme.color.brightBlue};
  color: ${theme.color.white};
`
const IsNotManagerContainer = styled(IsManagerContainer)`
  background-color: ${theme.color.plumMid};
`
