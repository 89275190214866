import styled from 'styled-components'
import { BodySmallMedium, Title1 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'
import {
  Arrow,
  Caret,
  Close,
  CreditCard,
  Describe,
  Dots,
  ErrorSign,
  Fan,
  GraphUp,
  HealthTest,
  Heart,
  HeartPalpitation,
  High,
  Home,
  HorizontalDots,
  Info,
  Invoice,
  InvoiceBlank,
  Lock,
  Menu,
  Paper,
  Polygon,
  Pulse,
  QuestionMark,
  Search,
  SearchLight,
  Spinner,
  Stethoscope,
  Success,
  User,
  VideoCamera,
  Womb,
} from '../../components/icons'
import { FilledCheckbox } from '../../components/icons/FilledCheckbox'
import { CheckRing } from '../../components/icons/CheckRing'
import { Download } from '../../components/icons/Download'

export const icons = [
  { name: 'Arrow', component: <Arrow /> },
  { name: 'Caret', component: <Caret /> },
  { name: 'CheckRing', component: <CheckRing /> },
  { name: 'Close', component: <Close /> },
  { name: 'CreditCard', component: <CreditCard /> },
  { name: 'Describe', component: <Describe /> },
  { name: 'Dots', component: <Dots /> },
  { name: 'Download', component: <Download /> },
  { name: 'Error', component: <ErrorSign /> },
  { name: 'Fan', component: <Fan /> },
  { name: 'FilledCheckbox', component: <FilledCheckbox /> },
  { name: 'GraphUp', component: <GraphUp /> },
  { name: 'HealthTest', component: <HealthTest /> },
  { name: 'Heart', component: <Heart /> },
  { name: 'HeartPalpitation', component: <HeartPalpitation /> },
  { name: 'High', component: <High /> },
  { name: 'Home', component: <Home /> },
  { name: 'HorizontalDots', component: <HorizontalDots /> },
  { name: 'Info', component: <Info /> },
  { name: 'Invoice', component: <Invoice /> },
  { name: 'InvoiceBlank', component: <InvoiceBlank /> },
  { name: 'Lock', component: <Lock /> },
  { name: 'Menu', component: <Menu /> },
  { name: 'Paper', component: <Paper /> },
  { name: 'Polygon', component: <Polygon /> },
  { name: 'Pulse', component: <Pulse /> },
  { name: 'Question Mark', component: <QuestionMark /> },
  { name: 'Search', component: <Search /> },
  { name: 'SearchLight', component: <SearchLight /> },
  { name: 'Spinner', component: <Spinner /> },
  { name: 'Stethoscope', component: <Stethoscope /> },
  { name: 'Success', component: <Success /> },
  { name: 'User', component: <User /> },
  { name: 'VideoCamera', component: <VideoCamera /> },
  { name: 'Womb', component: <Womb /> },
]

export const Icons = () => {
  return (
    <ComponentLayout>
      <StyledTitle1>Icons</StyledTitle1>
      <Grid $columns={6}>
        {icons.map((icon, index) => (
          <IconWithTextContainer key={index}>
            {icon.component}
            <BodySmallMedium>{icon.name}</BodySmallMedium>
          </IconWithTextContainer>
        ))}
      </Grid>
    </ComponentLayout>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
const IconWithTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
