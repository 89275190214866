export enum HealthCallStatus {
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED',
}

export type HealthCall = {
  id: string
  status: HealthCallStatus
  createdAt: Date
  validUntil: Date
}

export type HealthCallUserInformation = {
  personalNumber: string
  phoneNumber: string
}
