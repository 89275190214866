import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { Dots } from '../icons/Dots'
import { Title6, BodySmall } from '../styles/Typography'
import { ToggleButton } from './ToggleButton'
import { useSelectIsAdmin, useSelectUserFullName, useSelectUserOrganisationName } from '../../state/selectors'

import { MenuDropdown } from '../MenuDropdown'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

export const SidebarUserMenu = () => {
  const userFullName = useSelectUserFullName()
  const organisationName = useSelectUserOrganisationName()
  const isAdmin = useSelectIsAdmin()
  const { logout } = useAuth0()
  const navigate = useNavigate()
  return (
    <BottomContainer>
      <AccountContainer>
        <div>
          <StyledTitle6>{userFullName}</StyledTitle6>
          <BodySmall>{organisationName}</BodySmall>
        </div>

        <MenuDropdown
          anchorOrigin={{
            vertical: -20,
            horizontal: 140,
          }}
          menuItems={[
            {
              label: 'Mitt konto',
              onClick: () => {
                navigate('/profil')
              },
            },
            {
              label: 'Logga ut',
              onClick: () => logout({ logoutParams: { returnTo: window.location.origin } }),
            },
          ]}
        >
          <ThreeDotsWithHoover>
            <Dots size={26} />
          </ThreeDotsWithHoover>
        </MenuDropdown>
      </AccountContainer>
      {isAdmin && <ToggleButton />}
    </BottomContainer>
  )
}

const StyledTitle6 = styled(Title6)`
  white-space: normal;
  word-break: break-word;
`
const BottomContainer = styled.div`
  border-top: 1px ${theme.color.grey2} solid;
  padding: ${theme.spacing.mediumLarge}px ${theme.spacing.mediumLarge}px ${theme.spacing.medium}px;
`

const AccountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const ThreeDotsWithHoover = styled.button`
  margin-left: 8px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.6s;
  border: none;
  background-color: ${theme.color.beigeLight};

  &:hover {
    background-color: ${theme.color.beigeDark};
  }
`
