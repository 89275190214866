import { SVGProps } from 'react'

interface WaveInterface extends SVGProps<SVGSVGElement> {
  color?: string
}

export const WaveEnd = ({ color = '#fff', ...rest }: WaveInterface) => (
  <svg
    width="130"
    height="173"
    viewBox="0 0 96 173"
    xmlns="http://www.w3.org/2000/svg"
    // this fixes weird line issues on Safari:
    style={{ marginBottom: -1 }}
    {...rest}
    preserveAspectRatio="none"
  >
    <path d="M96 0C96 45 50 83 0 83V173H96L96 0Z" fill={color} />
  </svg>
)
