import styled from 'styled-components'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { BodyLarge, Title2, Title4 } from '../../../components/styles/Typography'
import { theme } from '../../../theme/theme'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { isILinkList } from '@estercare/ester-shared'
import { SupportBlock } from '../../../components/SupportBlock'

export const AdminSupportIndex = () => {
  const { data: supportPage, isLoading } = useGetGeneralPage('support-admin')
  if (isLoading) return <LoadingSpinner />

  const supportContent = supportPage?.items?.[0]?.fields
  if (!supportContent) return <PreviewGuard />

  const { title, description, contentBlocks } = supportContent

  return (
    <>
      <ComponentLayout>
        <StyledTitle2>{title}</StyledTitle2>
        <StyledBodyLarge>{description}</StyledBodyLarge>
      </ComponentLayout>
      {contentBlocks && (
        <ComponentLayout paddingTop={0}>
          {contentBlocks.map((block, index) => {
            return isILinkList(block) && <SupportBlock key={index} linkList={block.fields} />
          })}
        </ComponentLayout>
      )}
      <ComponentLayout paddingTop={0}>
        <OtherQuestions>
          <Title4>Har du några frågor eller funderingar?</Title4>
          <StyledBodyLarge>
            Maila till <a href="mailto:farnaz@estercare.com">farnaz@estercare.com</a> så hjälper vi dig!
          </StyledBodyLarge>
        </OtherQuestions>
      </ComponentLayout>
    </>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const OtherQuestions = styled.div`
  background-color: ${theme.color.beigeDark};
  padding: ${theme.spacing.xlarge}px;
  border-radius: ${theme.spacing.medium}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  max-width: 640px;
  a {
    color: ${theme.color.plumMid};
    font-weight: 500;
  }
`
