import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const SearchLight = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="6" stroke={color} />
      <path d="M20 20L17 17" stroke={color} strokeLinecap="round" />
    </svg>
  )
}
