import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import { ChartLegend } from './Legend'
import { BodySmallMedium } from '../styles/Typography'
import { ChartContainer, ChartHeader } from './shared'
import { ChartData } from './type'

type Props = {
  title: string
  data: ChartData[]
}

export const StackedBarChart = ({ title, data }: Props) => {
  const calculateBarGaps = (chartData: ChartData[]) => {
    const totalValue = chartData.reduce((sum, item) => sum + item.value, 0)
    const minGapWidth = 0.0002
    const gapProportion = 0.003
    const gapValue = Math.max(minGapWidth, totalValue * gapProportion)

    return chartData.reduce((acc, item, index) => {
      if (index > 0) {
        acc.push({ name: `gap${index}`, value: gapValue, color: 'transparent' })
      }
      acc.push(item)
      return acc
    }, [] as ChartData[])
  }

  const processedData = calculateBarGaps(data)
  const totalWidth = processedData.reduce((sum, item) => sum + item.value, 0)

  return (
    <ChartContainer>
      <ChartHeader>
        <BodySmallMedium>{title}</BodySmallMedium>
        <ChartLegend data={data} />
      </ChartHeader>
      <ResponsiveContainer width="100%" height={40}>
        <BarChart
          layout="vertical"
          data={[{ name: 'Total', ...Object.fromEntries(processedData.map((d) => [d.name, d.value])) }]}
          margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
          barSize={40}
        >
          <XAxis type="number" hide domain={[0, totalWidth]} />
          <YAxis type="category" dataKey="name" hide />
          {processedData.map((entry) => (
            <Bar key={entry.name} dataKey={entry.name} fill={entry.color} stackId="a" radius={4} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}
