import { appConfig } from '../../../../config'
import { TrainingStatus } from './types'

export const getUserTrainings = async ({ accessToken }: { accessToken: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/trainings/user`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}

export const setUserTrainingStatus = async ({
  accessToken,
  trainingData,
}: {
  accessToken: string
  trainingData: { status: TrainingStatus; trainingId: string }
}) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/trainings`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(trainingData),
  })

  return res.json()
}

export const getTrainingsFromOrganisationUsers = async ({
  accessToken,
  trainingId,
}: {
  accessToken: string
  trainingId: string
}) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/trainings/${trainingId}/statistics`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}
