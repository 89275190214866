import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.white
export const Womb = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 329 329" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.926 0.369786C181.571 2.18856 193.795 7.9942 204.498 18.2131C217.553 30.6786 225.369 50.2734 224.556 68.499C223.941 82.2822 219.407 94.534 210.747 105.813C209.355 107.626 191.505 125.801 165.151 152.238C129.922 187.577 121.761 195.935 121.412 197.032C121.176 197.774 121.047 199.387 121.125 200.616C121.238 202.39 121.483 203.159 122.311 204.338C123.929 206.642 125.816 207.793 128.355 208.023C132.724 208.42 129.127 211.688 177.768 163.114C204.89 136.029 222.704 118.503 224.325 117.308C233.642 110.437 243.499 106.376 254.359 104.936C261.8 103.95 270.698 104.404 278.105 106.149C288.57 108.613 299.175 114.099 307.216 121.206C319.306 131.892 326.983 146.961 328.623 163.224C329.128 168.233 329.124 265.418 328.619 270.063C325.643 297.405 306.852 319.659 280.682 326.832C272.237 329.147 280.135 329 164.381 328.997C48.8561 328.995 56.7721 329.14 48.2501 326.865C31.9179 322.504 17.6258 311.497 8.79651 296.481C4.33094 288.886 1.35027 279.779 0.289277 270.488C0.0214903 268.141 -0.0534198 236.194 0.0358426 162.223C0.173412 47.9004 0.0123857 57.0005 2.04057 49.0534C5.00863 37.4253 12.0653 25.6157 20.9747 17.3666C26.6682 12.0949 36.227 6.23534 43.1744 3.75787C46.726 2.49163 52.5862 1.08174 56.5407 0.542522C60.9698 -0.0615164 162.177 -0.219888 166.926 0.369786Z"
        fill={color}
      />
    </svg>
  )
}
