import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Label } from './styles/Typography'

type InputProps = {
  label?: string
} & InputHTMLAttributes<HTMLInputElement>

export const Input = ({ label, ...props }: InputProps) => {
  return (
    <InputWrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput {...props} />
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled(Label)`
  margin-bottom: ${theme.spacing.xsmall}px;
  font-size: ${theme.fontSize.xsmall}px;
  && {
    text-transform: none;
  }
`

const StyledInput = styled.input`
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  border-radius: ${theme.spacing.xsmall}px;
  padding: ${theme.spacing.medium}px ${theme.spacing.xsmall}px;
  border: 1px solid transparent;
  font-family: ${theme.font.body};
  outline: 0;

  &:focus {
    border: 1px solid ${theme.color.plum};
  }

  &:disabled {
    cursor: not-allowed;
  }
`
