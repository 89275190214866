import { useAuth0 } from '@auth0/auth0-react'
import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { QueryKeys } from '../../types'
import { getActiveHealthCalls, postHealthCall } from './healthCalls'
import { HealthCall, HealthCallUserInformation } from './types'

export const useGetActiveHealthCallsQuery = (): UseQueryResult<HealthCall[], Error> => {
  const { getAccessTokenSilently } = useAuth0()
  return useQuery({
    queryKey: [QueryKeys.HealthCalls, 'private', 'active'],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently()
      return await getActiveHealthCalls({ accessToken })
    },
  })
}

export const usePostHealthCallQuery = (
  options?: UseMutationOptions<Response, Error, HealthCallUserInformation, unknown>
): UseMutationResult<Response, Error, HealthCallUserInformation, unknown> => {
  const { getAccessTokenSilently } = useAuth0()
  return useMutation<Response, Error, HealthCallUserInformation, unknown>({
    mutationKey: [QueryKeys.HealthCalls, 'private', 'post'],
    mutationFn: async (userInformation) => {
      const accessToken = await getAccessTokenSilently()
      return await postHealthCall({ accessToken, userInformation })
    },
    ...options,
  })
}
