/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useState, useEffect, RefObject } from 'react'

export const useScrollPosition = (containerRef: RefObject<HTMLElement>) => {
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    let container: HTMLElement | null = null
    let cleanup: (() => void) | null = null

    const checkContainer = () => {
      container = containerRef.current
      return !!container
    }

    const updatePosition = () => {
      if (container) {
        setScrollPosition({
          x: container.scrollLeft,
          y: container.scrollTop,
        })
      }
    }

    const setupListeners = () => {
      if (!checkContainer()) {
        setTimeout(setupListeners, 100)
        return
      }

      updatePosition()

      container!.addEventListener('scroll', updatePosition, { passive: true })
      const resizeObserver = new ResizeObserver(updatePosition)
      resizeObserver.observe(container!)

      cleanup = () => {
        container!.removeEventListener('scroll', updatePosition)
        resizeObserver.disconnect()
      }
    }

    setupListeners()

    return () => {
      if (cleanup) cleanup()
    }
  }, [containerRef])

  return scrollPosition
}
