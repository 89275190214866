import { useEffect, useState } from 'react'
import { theme } from '../../../theme/theme'
import { OnClickButton } from '../onclick/OnClickButton'

export const ErrorButton = () => {
  const [throwError, setThrowError] = useState(false)
  useEffect(() => {
    if (throwError) throw new Error('An error occured!')
  }),
    [throwError]
  return <OnClickButton text="Throw Error" onClick={() => setThrowError(true)} buttonColor={theme.color.plumMid} />
}
