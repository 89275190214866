import { ComponentLayout } from '../components/layouts/ComponentLayout'
import { Title2 } from '../components/styles/Typography'
import { theme } from '../theme/theme'
import { Grid } from '../components/Grid'
import styled from 'styled-components'
import { useSelectFirstName, useSelectLastName, useSelectUserEmail } from '../state/selectors'
import { useState, useEffect } from 'react'
import { ViewLayout } from '../components/layouts/ViewLayout'
import { Input } from '../components/Input'

export const Profile = () => {
  const firstName = useSelectFirstName()
  const lastName = useSelectLastName()
  const email = useSelectUserEmail()

  const [profileInfo, setProfileInfo] = useState({
    firstName: '',
    lastName: '',
    birthYear: '',
    geneticSex: '',
    email: '',
  })

  useEffect(() => {
    setProfileInfo((prevState) => ({
      ...prevState,
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
    }))
  }, [firstName, lastName, email])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setProfileInfo((prev) => ({ ...prev, [name]: value }))
  }

  // const handleSave = () => {
  //   // Implementera sparlogik här
  // }

  return (
    <ViewLayout>
      <ComponentLayout>
        <Title2>Mitt konto</Title2>
        <StyledGrid>
          <Input
            label="Förnamn"
            disabled
            name="firstName"
            value={profileInfo.firstName}
            onChange={handleInputChange}
            type="text"
            placeholder="Förnamn"
          />
          <Input
            label="Efternamn"
            disabled
            name="lastName"
            value={profileInfo.lastName}
            onChange={handleInputChange}
            type="text"
            placeholder="Efternamn"
          />
          {/* Hide födelseår and genetiskt kön until we have that data in db*/}
          {/* <Input
            label="Födelseår"
            disabled
            name="birthYear"
            value={profileInfo.birthYear}
            onChange={handleInputChange}
            type="text"
            placeholder="Födelseår"
          />
          <Input
            label="Genetiskt kön"
            disabled
            name="geneticSex"
            value={profileInfo.geneticSex}
            onChange={handleInputChange}
            type="text"
            placeholder="Genetiskt kön"
          /> */}
        </StyledGrid>

        <Input
          label="Email"
          disabled
          name="email"
          value={profileInfo.email}
          onChange={handleInputChange}
          type="email"
          placeholder="Email"
        />
        {/* <ButtonWrapper> */}
        {/* <ButtonGroup> */}
        {/* <OnClickButton
              disabled
              secondary
              text="Byt lösenord"
              onClick={() => {
                /* Implementera lösenordsbyte */
        /*  }}
            /> */}
        {/* <OnClickButton
              disabled
              secondary
              text="Ta bort mitt konto och radera all data"
              onClick={() => {
                /* Implementera kontoborttagning */
        /*  }}
            /> */}
        {/* </ButtonGroup> */}
        {/* <OnClickButton disabled text="Spara förändringar" onClick={handleSave} /> */}
        {/* </ButtonWrapper> */}
      </ComponentLayout>
    </ViewLayout>
  )
}

const StyledGrid = styled(Grid)`
  padding-top: ${theme.spacing.large}px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${theme.spacing.mediumLarge}px;
  row-gap: 20px;

  @media screen and (max-width: ${theme.breakpoint.large}px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`
