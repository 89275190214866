import './datadog.ts'
import { Routes, Route, Outlet } from 'react-router-dom'
import { AuthenticationGuard } from './components/auth/AuthenticationGuard'
import { Home } from './views/employee/Home'
import { ComponentLibrary } from './views/componentLibrary/Index'
import { ComponentLibraryBaseLayout } from './components/layouts/ComponentLibraryBaseLayout'
import { Typography } from './views/componentLibrary/Typography'
import { BaseLayout } from './components/layouts/BaseLayout'
import { Index } from './views/Index'
import { AdminViewGuard } from './components/auth/AuthorizationGuard'
import { Colors } from './views/componentLibrary/Colors'
import { Grids } from './views/componentLibrary/Grid'
import { Buttons } from './views/componentLibrary/Buttons'
import { Icons } from './views/componentLibrary/Icons.tsx'
import { ServiceIndex } from './views/admin/services/Index.tsx'
import { Employees } from './views/admin/Employees.tsx'
import { Invoices } from './views/admin/Invoices.tsx'
import { HealthServiceIndex } from './views/employee/healthServices/Index.tsx'
import { Archive } from './views/employee/knowledgeBank/Index.tsx'
import { PolicyAdmin } from './views/admin/services/Policy.tsx'
import { TrainingIndex } from './views/employee/trainings/Index.tsx'
import { Training } from './views/employee/trainings/Training.tsx'
import { ContentfulBlocks } from './views/componentLibrary/ContentfulBlocks.tsx'
import { Article } from './views/employee/knowledgeBank/Article.tsx'
import { Podcast } from './views/employee/knowledgeBank/Podcast.tsx'
import { EmployeeSupportIndex } from './views/employee/support/Index.tsx'
import { HomeAdmin } from './views/admin/Home.tsx'
import { Service } from './views/admin/services/Service.tsx'
import { ErrorPage } from './views/Error.tsx'
import { Login } from './views/Login.tsx'
import { Profile } from './views/Profile.tsx'
import { NotFound } from './views/Notfound.tsx'
import { Screening } from './views/employee/healthServices/Screening.tsx'
import { HealthCall } from './views/employee/healthServices/HealthCall.tsx'
import { Statistics } from './views/admin/Statistics.tsx'
import { EmployeeSupport } from './views/employee/support/Support.tsx'
import { AdminSupportIndex } from './views/admin/support/Index.tsx'
import { AdminSupport } from './views/admin/support/Support.tsx'
import { CreateAccount } from './views/CreateAccount.tsx'

export const App = () => {
  return (
    <BaseLayout>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route element={<AuthenticationGuard />}>
          <Route path="*" element={<NotFound />} />
          <Route path="profil" element={<Profile />} />
          <Route path="admin" element={<AdminViewGuard />}>
            <Route index element={<HomeAdmin />} />
            <Route path="dashboard" element={<HomeAdmin />} />
            <Route path="tjanster" element={<Outlet />}>
              <Route index element={<ServiceIndex />} />
              <Route path="policy" element={<PolicyAdmin />} />
              <Route path=":id" element={<Service />} />
            </Route>
            <Route path="statistik" element={<Statistics />} />
            <Route path="medarbetare" element={<Employees />} />
            <Route path="fakturering" element={<Invoices />} />
            <Route path="support" element={<Outlet />}>
              <Route index element={<AdminSupportIndex />} />
              <Route path=":id" element={<AdminSupport />} />
            </Route>
          </Route>
          <Route path="portal" element={<Outlet />}>
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="halsotjanster" element={<Outlet />}>
              <Route index element={<HealthServiceIndex />} />
              <Route path="screening" element={<Screening />} />
              <Route path="halsosamtal" element={<HealthCall />} />
            </Route>
            <Route path="utbildning" element={<Outlet />}>
              <Route index element={<TrainingIndex />} />
              <Route path=":id" element={<Training />} />
            </Route>
            <Route path="kunskapsbank" element={<Outlet />}>
              <Route index element={<Archive />} />
              <Route path="artiklar/:id" element={<Article />} />
              <Route path="podcasts/:id" element={<Podcast />} />
            </Route>
            <Route path="support" element={<Outlet />}>
              <Route index element={<EmployeeSupportIndex />} />
              <Route path=":id" element={<EmployeeSupport />} />
            </Route>
          </Route>
        </Route>
        <Route path="component-library" element={<ComponentLibraryBaseLayout />}>
          <Route index element={<ComponentLibrary />} />
          <Route path="colors" element={<Colors />} />
          <Route path="typography" element={<Typography />} />
          <Route path="grid" element={<Grids />} />
          <Route path="buttons" element={<Buttons />} />
          <Route path="icons" element={<Icons />} />
          <Route path="contentful-blocks" element={<ContentfulBlocks />} />
        </Route>
      </Routes>
    </BaseLayout>
  )
}
