import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const Describe = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, ...rest }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M19.8 17.4H11.4L6.6 21V17.4H4.2C3.88174 17.4 3.57652 17.2736 3.35147 17.0485C3.12643 16.8235 3 16.5183 3 16.2V4.2C3 3.88174 3.12643 3.57652 3.35147 3.35147C3.57652 3.12643 3.88174 3 4.2 3H19.8C20.1183 3 20.4235 3.12643 20.6485 3.35147C20.8736 3.57652 21 3.88174 21 4.2V16.2C21 16.5183 20.8736 16.8235 20.6485 17.0485C20.4235 17.2736 20.1183 17.4 19.8 17.4Z"
      stroke={color}
    />
  </svg>
)
