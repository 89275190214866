import { FC } from 'react'
import { theme } from '../../../theme/theme'
import { LoadingCheck } from '../../LoadingCheck'
import { ButtonProps, Button } from '../Button'

interface OnClickButtonProps extends ButtonProps {
  onClick: () => void
}

export const OnClickButton: FC<OnClickButtonProps> = ({
  onClick,
  buttonStyle,
  secondary,
  buttonColor = theme.color.plum,
  disabled,
  loading,
  text,
  icon,
}) => {
  return (
    // @ts-expect-error - A bug in styled components v6 fails to recognize that "as"-prop should change the type. See issue: https://github.com/styled-components/styled-components/issues/4112
    <Button
      as="button"
      onClick={onClick}
      style={buttonStyle}
      $secondary={secondary}
      $buttonColor={buttonColor}
      disabled={disabled}
    >
      {LoadingCheck(text, loading)}
      {!loading && icon}
    </Button>
  )
}
