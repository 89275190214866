import styled from 'styled-components'
import { ViewLayout } from '../components/layouts/ViewLayout'
import { theme } from '../theme/theme'
import { Title1 } from '../components/styles/Typography'
import errorImage from '../../public/images/three-women.png'
import { TextLink } from '../components/TextLink'

export const NotFound = () => {
  return (
    <ViewLayout>
      <ErrorPageWrapper>
        <Title1>
          Sidan du söker finns <br /> tyvärr inte
        </Title1>
        <StyledImage src={errorImage} alt="Tre kvinnor som samtalar (illustration)" sizes="100vw" />
        <StyledLinkWrapper>
          <TextLink href="/portal" text="Gå tillbaka till vår startsida" />
        </StyledLinkWrapper>
      </ErrorPageWrapper>
    </ViewLayout>
  )
}

const ErrorPageWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${theme.spacing.large}px;
  padding-top: 80px;
  h1 {
    min-width: 332px;
  }
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    padding-top: 0px;
    flex-direction: column;
    align-items: start;
  }
`

const StyledImage = styled.img`
  object-fit: contain;
  width: 388px;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    display: none;
  }
`

const StyledLinkWrapper = styled.div`
  margin-top: ${theme.spacing.medium}px;
  display: none;
  @media screen and (max-width: ${theme.breakpoint.medium}px) {
    display: inline-flex;
  }
`
