import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { BodyXl } from '../components/styles/Typography'

export const ErrorPage = () => {
  const location = useLocation()
  const errorMessage = location.state?.errorMessage || 'An error occurred'

  return (
    <ErrorWrapper>
      <BodyXl>{errorMessage}</BodyXl>
    </ErrorWrapper>
  )
}

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.color.plum};
`
