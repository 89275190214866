import styled from 'styled-components'
import { Modal } from './Modal'
import { Title3, BodyLarge } from './styles/Typography'
import { OnClickButton } from './buttons/onclick/OnClickButton'
import { useNavigate } from 'react-router-dom'
import { theme } from '../theme/theme'

interface Props {
  onClose: () => void
  onConfirm?: () => void
  path?: string
  courseTitle?: string
}

export const CloseTrainingModal = ({ onClose, onConfirm, path }: Props) => {
  const navigate = useNavigate()
  const handleConfirm = () => {
    onConfirm && onConfirm()
    path && navigate(path)
  }
  return (
    <Modal onClose={onClose}>
      <ContentContainer>
        <Title3>Är du säker på att du vill avbryta?</Title3>
        <StyledBodyLarge>Om du lämnar utbildningen nu kommer dina framsteg inte att sparas. </StyledBodyLarge>
        <ButtonContainer>
          <OnClickButton text="Nej, fortsätt utbildningen" onClick={onClose} />
          <OnClickButton text="Ja,  avbryt och lämna" onClick={handleConfirm} secondary />
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  justify-content: center;
  max-width: 400px;
  text-align: center;

  h3 {
    margin-top: ${theme.spacing.medium}px;
  }
`

const StyledBodyLarge = styled(BodyLarge)`
  margin-top: ${theme.spacing.mediumLarge}px;
  margin-bottom: ${theme.spacing.large}px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  button {
    &:first-child {
      margin-bottom: ${theme.spacing.medium}px;
    }
  }
`
