import { TrainingStatistics } from '../../api/ester-b2b-api/private/trainings/types'
import { ChartData } from '../../components/charts/type'

export const getTrainingData = (trainingData: TrainingStatistics, numberOfEmployees: number) => {
  const completedTrainings = trainingData.completed
  const startedTrainings = trainingData.inProgress
  const uncompletedTrainings = numberOfEmployees - completedTrainings - startedTrainings

  const chartData: ChartData[] = [
    {
      name: 'Genomfört utbildning',
      value: completedTrainings,
      color: '#4DBA99',
    },
    {
      name: 'Påbörjat utbildning',
      value: startedTrainings,
      color: '#AB91CE',
    },
    {
      name: 'Ej påbörjat utbildning',
      value: uncompletedTrainings,
      color: '#EF643A',
    },
  ]

  return chartData
}
