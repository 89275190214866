import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Title2 } from '../../../components/styles/Typography'
import { useGetServicePages } from '../../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { HealthServiceCard } from '../../../components/HealthServiceCard'
import { Services } from '@estercare/ester-shared'
import { isServiceIdentifier } from '../../../utils/typeGuards'
import { useSelectActiveServicesFromContentful } from '../../../state/selectors'

export const HealthServiceIndex = () => {
  const { data, isLoading } = useGetServicePages()
  const ServiceIndex = data?.items || []

  const activeServices = useSelectActiveServicesFromContentful(ServiceIndex, 'EMPLOYEE')
  if (isLoading) return <LoadingSpinner />
  if (!data) return <PreviewGuard />

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <Title2>Hälsotjänster</Title2>
      </ComponentLayout>
      <ComponentLayout>
        {activeServices &&
          activeServices.map((block, index) => {
            const { title, slug, description, image, iconType, serviceIdentifier } = block.fields
            if (!title || !slug || !image || !isServiceIdentifier(serviceIdentifier)) {
              return <PreviewGuard key={index} />
            }
            return (
              <HealthServiceCard
                key={index}
                title={title}
                description={description || ''}
                linkTo={slug}
                image={image}
                icon={iconType}
                serviceId={serviceIdentifier as Services}
              />
            )
          })}
      </ComponentLayout>
    </>
  )
}
