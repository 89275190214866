import { Services } from '@estercare/ester-shared'
import { appConfig } from '../../../../config'

export const getActiveServices = async ({ accessToken }: { accessToken: string }) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/services/activated`, {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return res.json()
}

export const requestServiceActivation = async ({
  accessToken,
  serviceData,
}: {
  accessToken: string
  serviceData: { serviceName: Services }
}) => {
  const res = await fetch(`${appConfig.serverRootUrl}/api/private/services/activationRequest`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(serviceData),
  })

  if (!res.ok) {
    const errorBody = await res.text()
    throw new Error(`Request failed with status ${res.status}: ${errorBody}`)
  }

  return
}
