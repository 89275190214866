import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.black

export const GraphUp = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.333496" width={size} height={size} rx="20" fill="#F7F2F4" />
      <path d="M10.3335 10V30H31.3335" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.5205 23.1719L28.8335 16.9999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.3335 20.5L23.3335 23.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3335 23.5L16.3335 19.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17.8335 18C18.6619 18 19.3335 18.6716 19.3335 19.5C19.3335 20.3284 18.6619 21 17.8335 21C17.0051 21 16.3335 20.3284 16.3335 19.5C16.3335 18.6716 17.0051 18 17.8335 18Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5835 23C25.4119 23 26.0835 23.6716 26.0835 24.5C26.0835 25.3284 25.4119 26 24.5835 26C23.7551 26 23.0835 25.3284 23.0835 24.5C23.0835 23.6716 23.7551 23 24.5835 23Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.3335 14C30.1619 14 30.8335 14.6716 30.8335 15.5C30.8335 16.3284 30.1619 17 29.3335 17C28.5051 17 27.8335 16.3284 27.8335 15.5C27.8335 14.6716 28.5051 14 29.3335 14Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
