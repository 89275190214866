import { MenuItem } from './types'

export const componentLibraryMenu: MenuItem[] = [
  { name: 'Colors', path: 'component-library/colors' },
  { name: 'Typography', path: 'component-library/typography' },
  { name: 'Buttons', path: 'component-library/buttons' },
  { name: 'Grid', path: 'component-library/grid' },
  { name: 'Icons', path: 'component-library/icons' },
  { name: 'Contentful blocks', path: 'component-library/contentful-blocks' },
]

export const isComponentLibraryMenu = (path: string) => {
  return path.includes('/component-library')
}
