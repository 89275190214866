import { getIcon, parseContenfulImageUrl } from '../contentful/utils'
import { theme } from '../theme/theme'
import { BlockItem } from './BlockItem'
import { BodySmall, Title6 } from './styles/Typography'
import styled from 'styled-components'
import { FC } from 'react'
import { isTablet, isMobile } from '../utils/devices'
import { useWindowWidth } from '../hooks/useWindowWidth'
import { Asset } from 'contentful'
import { Services } from '@estercare/ester-shared'
import { Link } from 'react-router-dom'

interface Props {
  title: string
  description: string
  linkTo: string
  image: Asset
  serviceId: Services
  icon?: string
}

export const HealthServiceCard: FC<Props> = ({ title, description, icon, image, linkTo }) => {
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)
  const direction = isMobileDevice ? 'column' : 'row'
  const height = isMobileDevice ? 572 : 320

  return (
    <Link to={linkTo} style={{ textDecoration: 'none' }}>
      <CardContainer>
        <BlockItem direction={direction} height={height}>
          <ContentContainer>
            <>
              {icon && (
                <IconContainer $isMobileDevice={isMobileDevice}>
                  {getIcon(icon, isMobileDevice ? 18 : 24, theme.color.plumMid)}
                </IconContainer>
              )}
              <StyledTitle6>{title}</StyledTitle6>
              <TextContainer>
                <BodySmall>{description}</BodySmall>
              </TextContainer>
            </>
          </ContentContainer>
          <ImageContainer>
            <StyledImage src={parseContenfulImageUrl(image)} />
          </ImageContainer>
        </BlockItem>
      </CardContainer>
    </Link>
  )
}

export const CardContainer = styled.div`
  margin-bottom: ${theme.spacing.large}px;
  transition: all 0.3s ease;
  border-radius: ${theme.spacing.medium}px;
  &:hover {
    transform: translateY(-2px);
    box-shadow:
      0px 2px 4px 0px rgba(0, 0, 0, 0.08),
      0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  padding: ${theme.spacing.large}px;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    width: 100%;
    height: 100%;
    a {
      width: 100%;
    }
  }
`

const IconContainer = styled.div<{ $isMobileDevice: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.color.plumlight};
  margin-bottom: ${({ $isMobileDevice }) => ($isMobileDevice ? theme.spacing.medium : theme.spacing.large)}px;
`

const TextContainer = styled.div`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const ImageContainer = styled.div`
  display: flex;
  width: 50%;
  @media screen and (max-width: ${theme.breakpoint.large}px) {
    width: 100%;
    max-height: 50%;
    order: -1;
  }
  @media screen and (max-width: ${theme.breakpoint.small}px) {
    max-height: 45%;
  }
`

const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: ${theme.spacing.medium}px;
`
