import styled from 'styled-components'
import { Title1 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'
import { BlockItem } from '../../components/BlockItem'

export const Grids = () => {
  const renderItems = ({ length, boxHeight }: { length: number; boxHeight?: number }) => {
    const items = []
    for (let i = 0; i < length; i++) {
      items.push(<BlockItem key={i} backgroundColor={theme.color.grey3} height={boxHeight} />)
    }
    return items
  }

  return (
    <>
      <ComponentLayout>
        <StyledTitle1>Grid Layout Examples</StyledTitle1>
        <StyledGrid>{renderItems({ length: 1, boxHeight: 100 })}</StyledGrid>
        <StyledGrid>{renderItems({ length: 2, boxHeight: 100 })}</StyledGrid>
        <StyledGrid>{renderItems({ length: 3, boxHeight: 100 })}</StyledGrid>
      </ComponentLayout>
      <ComponentLayout>
        <StyledGrid $columns={2}>{renderItems({ length: 4, boxHeight: 200 })}</StyledGrid>
      </ComponentLayout>
      <ComponentLayout>
        <StyledGrid $columns={2}>
          <BlockItem backgroundColor={theme.color.grey3} gridRow="1 / span 2" />
          <BlockItem backgroundColor={theme.color.grey3} height={100} />
          <BlockItem backgroundColor={theme.color.grey3} height={100} />
        </StyledGrid>
      </ComponentLayout>
      <ComponentLayout>
        <StyledGrid $columns={4}>
          <BlockItem backgroundColor={theme.color.grey3} gridColumn="1 / span 2" />
          <BlockItem backgroundColor={theme.color.grey3} height={200} />
          <BlockItem backgroundColor={theme.color.grey3} height={200} />
        </StyledGrid>
      </ComponentLayout>
      <ComponentLayout>
        <StyledGrid>{renderItems({ length: 15, boxHeight: 100 })}</StyledGrid>
      </ComponentLayout>
      <ComponentLayout>
        <StyledGrid>{renderItems({ length: 3, boxHeight: 300 })}</StyledGrid>
      </ComponentLayout>
    </>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const StyledGrid = styled(Grid)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
