import { ICenteredTextBlockFields } from '@estercare/ester-shared'
import { Title4 } from './styles/Typography'
import styled from 'styled-components'

export const CenteredText = ({ textField }: ICenteredTextBlockFields) => {
  const content = textField?.content[0]?.content[0]
  const text = content && 'value' in content ? (content.value as string) : ''

  return <StyledBodyLarge>{text}</StyledBodyLarge>
}
const StyledBodyLarge = styled(Title4)`
  && {
    text-align: center;
    max-width: 548px;
    margin: 0 auto;
  }
`
