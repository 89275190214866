import styled from 'styled-components'
import { BodyLarge, Title1 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'

export const Colors = () => {
  const colorsArray = Object.entries(theme.color)
  return (
    <ComponentLayout>
      <StyledTitle1>Colors</StyledTitle1>
      <Grid>
        {colorsArray.map((color, index) => (
          <ColorContainer key={index}>
            <Color $color={color[1]} />
            <BodyLarge>{color[0]}</BodyLarge>
            <BodyLarge>{color[1]}</BodyLarge>
          </ColorContainer>
        ))}
      </Grid>
    </ComponentLayout>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const ColorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Color = styled.article<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 5px;
  height: 100px;
  margin-bottom: 10px;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
    0px 3px 9px 0px rgba(0, 0, 0, 0.05);
`
