import styled from 'styled-components'
import { theme } from '../../../theme/theme'
import { useParams } from 'react-router-dom'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { useGetGeneralPage } from '../../../api/contentful/contentfulQueries'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  IHighlightedListBlockFields,
  isIFAQ,
  isIHighlightedListBlock,
  isImageBlock,
  Services,
} from '@estercare/ester-shared'
import { Header } from '../../../components/Header'
import { DynamicBlock } from '../../../components/DynamicBlock'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { Wave } from '../../../components/wave/Wave'
import { getBackgroundColor, hasBlockBackgroundColorProperty } from '../../../contentful/utils'
import { OnClickButton } from '../../../components/buttons/onclick/OnClickButton'
import { useState } from 'react'
import { Success } from '../../../components/icons'
import { BodyLarge, Title3 } from '../../../components/styles/Typography'
import { ErrorSign } from '../../../components/icons/ErrorSign'
import { Spinner } from '../../../components/icons/Spinner'
import { Modal } from '../../../components/Modal'
import { useSelectActiveServices, useSelectUserEmail } from '../../../state/selectors'
import { useWindowWidth } from '../../../hooks/useWindowWidth'
import { isTablet, isMobile } from '../../../utils/devices'
import { useRequestServiceActivationMutation } from '../../../api/ester-b2b-api/private/services/serviceQueries'

export const Service = () => {
  const { id } = useParams()
  const width = useWindowWidth()
  const isMobileDevice = isTablet(width) || isMobile(width)

  const activeServices = useSelectActiveServices()
  const email = useSelectUserEmail()
  const servicePageQuery = useGetGeneralPage(id as string)
  const { mutate: sendActivationRequest } = useRequestServiceActivationMutation()

  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState<boolean>()
  const [buttonIcon, setButtonIcon] = useState<React.ReactNode>()

  if (servicePageQuery.isLoading) return <LoadingSpinner />
  if (!servicePageQuery.data?.items[0]?.fields || !email) return <PreviewGuard />
  if (!id) throw new Error('No slug provided')

  const { title, description, shortDescription, slug, contentBlocks, serviceIdentifier } =
    servicePageQuery.data.items[0].fields

  const isActive = activeServices.some((service) => service === serviceIdentifier)
  const waveColor = getBackgroundColor(hasBlockBackgroundColorProperty(contentBlocks?.[0]) || '')
  const buttonText = isMobileDevice
    ? 'Få ett prisförslag'
    : 'Få ett prisförslag och aktivera tjänsten för dina anställda'

  if (!description || !shortDescription || !slug || !title || !contentBlocks) return <PreviewGuard />

  const sendServiceMail = () => {
    setIsLoading(true)
    setShowModal(true)
    sendActivationRequest(
      { serviceName: serviceIdentifier as Services },
      {
        onSuccess: () => {
          setIsLoading(false)
          setIsSuccess(true)
          setButtonIcon(<Success color={theme.color.white} size={24} />)
        },
        onError: () => {
          setIsLoading(false)
          setIsSuccess(false)
          setButtonIcon(<ErrorSign color={theme.color.white} size={24} />)
        },
      }
    )
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const renderModalContent = () => {
    if (isLoading) {
      return (
        <>
          <Spinner size={100} color={theme.color.plum} />
          <TextBlock>
            <StyledBodyLarge>Vänligen vänta medan vi behandlar din förfrågan.</StyledBodyLarge>
          </TextBlock>
        </>
      )
    }

    if (isSuccess) {
      return (
        <>
          <Success />
          <TextBlock>
            <Title3>Vi har mottagit din förfrågan!</Title3>
            <BodyLarge>
              Inom 1-2 arbetsdagar återkommer med ett prisförslag. Därefter hjälper vi er att snabbt och smidigt komma
              igång med tjänsten på ert företag.
            </BodyLarge>
          </TextBlock>
        </>
      )
    }

    return (
      <>
        <ErrorSign />
        <TextBlock>
          <Title3>Något gick fel</Title3>
          <BodyLarge>Prova en gång till eller kontakta vår support.</BodyLarge>
        </TextBlock>
      </>
    )
  }

  return (
    <>
      <Header
        backButtonLink="/admin/tjanster"
        backgroundColor={theme.color.transparent}
        title={title}
        description={description}
        button={
          isActive ? (
            <HeaderButton>AKTIV</HeaderButton>
          ) : (
            <OnClickButton
              loading={isLoading}
              onClick={() => {
                sendServiceMail()
              }}
              icon={buttonIcon}
              text={buttonText}
              buttonStyle={{ width: isMobileDevice ? '100%' : 'auto' }}
            />
          )
        }
        noPaddingBottom
        maxWidth={740}
      />
      <StyledWaveWrapper>
        <Wave color={waveColor} />
      </StyledWaveWrapper>

      {Array.isArray(contentBlocks) &&
        contentBlocks.map((block, index) => {
          const isListItemBlockComponent = isIHighlightedListBlock(block)
          const isFAQ = isIFAQ(block)
          const isImageBlockComponent = isImageBlock(block)
          const isListBlockComponent = isIHighlightedListBlock(block)
          const isFirstBlock = index === 0

          return (
            <ComponentLayout
              backgroundColor={
                isListItemBlockComponent || isFAQ
                  ? getBackgroundColor((block.fields as IHighlightedListBlockFields).backgroundColor)
                  : isImageBlockComponent
                    ? '#393F54'
                    : 'transparent'
              }
              paddingTop={isFirstBlock || isImageBlockComponent || isListBlockComponent ? 1 : 64}
              paddingBottom={isImageBlockComponent ? 0 : 64}
              key={index}
              noMaxWidth
            >
              <DynamicBlock block={block} />
            </ComponentLayout>
          )
        })}
      {showModal && (
        <Modal onClose={closeModal}>
          <ContentContainer>{renderModalContent()}</ContentContainer>
        </Modal>
      )}
    </>
  )
}

const StyledBodyLarge = styled(BodyLarge)`
  margin-top: ${theme.spacing.large}px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  justify-content: center;
  width: 300px;
`
const TextBlock = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.medium}px;
  p {
    padding-top: ${theme.spacing.xsmall}px;
    color: ${theme.color.grey5};
  }
`

const HeaderButton = styled.button`
  display: inline-flex;
  align-self: flex-start;
  background-color: ${theme.color.green};
  padding: 6px;
  color: ${theme.color.white};
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  z-index: 5;
  position: relative;
`
const StyledWaveWrapper = styled.div`
  margin-top: -30px;
`
