import { TrainingId } from '@estercare/ester-shared'

export enum TrainingStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type Training = {
  id: string
  trainingId: TrainingId
  userId: string
  status: TrainingStatus
  createdAt: string
  updatedAt: string
}

export interface TrainingStatistics {
  completed: number
  inProgress: number
}
