import styled from 'styled-components'
import { Title1 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'
import { ErrorButton } from '../../components/buttons/error/ErrorButton'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'
import { Info } from '../../components/icons'

export const Buttons = () => {
  return (
    <ComponentLayout>
      <StyledTitle1>Buttons</StyledTitle1>
      <Grid $columns={2}>
        <OnClickButton text="Primary Button" onClick={() => {}} />
        <OnClickButton text="Primary Loading Button" loading onClick={() => {}} />
        <OnClickButton text="Primary Button" onClick={() => {}} icon={<Info color={theme.color.white} size={20} />} />
        <OnClickButton text="Primary Orange Button" buttonColor={theme.color.orange} onClick={() => () => {}} />
        <OnClickButton text="Secondary Button" secondary onClick={() => {}} />
        <OnClickButton text="Disabled Button" disabled onClick={() => {}} />
        <ErrorButton />
      </Grid>
    </ComponentLayout>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
