import { FC } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { SuperImage } from './SuperImage'
import { ISpecialistFields } from '@estercare/ester-shared'
import { BodyLarge, Title6 } from './styles/Typography'

export const Author: FC<ISpecialistFields> = ({ firstName, lastName, jobTitle, image }) => {
  return (
    <AuthorContainer>
      <ImageContainer>
        <SuperImage asset={image} fill />
      </ImageContainer>
      <div>
        <StyledBodyLarge>Artikeln är skriven av:</StyledBodyLarge>
        <Title6>{`${firstName} ${lastName}`}</Title6>
        <StyledBodyLarge> {jobTitle}</StyledBodyLarge>
      </div>
    </AuthorContainer>
  )
}

const AuthorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${theme.spacing.large}px;
  padding-top: ${theme.spacing.large}px;

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    padding-bottom: ${theme.spacing.xlarge}px;
    padding-top: ${theme.spacing.xlarge}px;
  }
`

const ImageContainer = styled.div`
  height: 75px;
  width: 75px;
  border-radius: 110px;
  overflow: hidden;
  position: relative;
  margin-right: ${theme.spacing.large}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  color: ${theme.color.grey5};
`
