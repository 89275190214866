import { SVGProps } from 'react'

interface WaveInterface extends SVGProps<SVGSVGElement> {
  color?: string
}

export const WaveStart = ({ color = '#fff', ...rest }: WaveInterface) => (
  <svg
    width="150"
    height="90"
    viewBox="0 0 111 90"
    xmlns="http://www.w3.org/2000/svg"
    // this fixes a weird line issue on Safari:
    style={{ marginBottom: -1 }}
    {...rest}
    preserveAspectRatio="none"
  >
    <path d="M111 0C91 0 0 0 0 90H111V0H111Z" fill={color} />
  </svg>
)
