import styled from 'styled-components'
import { theme } from '../theme/theme'
import { FC } from 'react'
import { Label } from './styles/Typography'

interface TagProps {
  text: string
  color?: string
}

export const Tag: FC<TagProps> = ({ text, color = theme.color.green }) => {
  return <TagLabel $color={color}>{text}</TagLabel>
}

const TagLabel = styled(Label)<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  color: ${theme.color.white};
  padding: 6px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  margin-left: auto;
`
