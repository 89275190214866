import styled from 'styled-components'
import { ComponentLayout } from '../../../components/layouts/ComponentLayout'
import { BodyLarge, Title2, Title4 } from '../../../components/styles/Typography'
import { ServiceCard } from '../../../components/ServiceCard'
import { theme } from '../../../theme/theme'
import { useGetServicePages } from '../../../api/contentful/contentfulQueries'
import { PreviewGuard } from '../../../components/PreviewGuard'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import * as IconComponents from '../../../components/icons'
import React from 'react'
import {
  useSelectActiveServicesFromContentful,
  useSelectInactiveServicesFromContentful,
} from '../../../state/selectors'
import { Grid } from '../../../components/Grid'

export const ServiceIndex = () => {
  const { data, isLoading } = useGetServicePages()
  const ServiceIndex = data?.items || []

  const activeServices = useSelectActiveServicesFromContentful(ServiceIndex, 'ADMIN')
  const inactiveServices = useSelectInactiveServicesFromContentful(ServiceIndex, 'ADMIN')

  if (isLoading) return <LoadingSpinner />
  if (!data) return <PreviewGuard />
  const haveActiveServices = activeServices?.length > 0

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <StyledTitle2>{haveActiveServices ? 'Aktiverade tjänster' : 'Tjänster'}</StyledTitle2>
        {haveActiveServices && (
          <>
            <BodyLarge>Här har vi samlat alla tjänster som ingår i vår hälsoportal.</BodyLarge>
            <BodyLarge>
              Som administratör har du möjlighet att enkelt aktivera de tjänster som du vill ska ingå i ert erbjudande.
            </BodyLarge>
          </>
        )}
      </ComponentLayout>
      {haveActiveServices && (
        <ComponentLayout>
          <Grid $columns={2}>
            {activeServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }, index) =>
              !id || !title || !slug ? (
                <PreviewGuard key={index} />
              ) : (
                <ServiceCard
                  key={id}
                  title={title!}
                  description={shortDescription || ''}
                  linkTo={`/admin/tjanster/${slug}`}
                  isActive
                  icon={
                    iconType &&
                    React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                      size: 22,
                      color: theme.color.plumMid,
                    })
                  }
                />
              )
            )}
          </Grid>
        </ComponentLayout>
      )}

      {inactiveServices.length > 0 && (
        <InactiveServicesContainer>
          <ComponentLayout backgroundColor={theme.color.beigeDark} paddingBottom={90}>
            <StyledTitle6>Lägg till fler tjänster</StyledTitle6>
            <Grid $columns={2}>
              {inactiveServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }, index) =>
                !id || !title || !slug ? (
                  <PreviewGuard key={index} />
                ) : (
                  <ServiceCard
                    key={id}
                    title={title!}
                    description={shortDescription || ''}
                    linkTo={`/admin/tjanster/${slug}`}
                    icon={
                      iconType &&
                      React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                        size: 22,
                        color: theme.color.plumMid,
                      })
                    }
                  />
                )
              )}
            </Grid>
          </ComponentLayout>
        </InactiveServicesContainer>
      )}
    </>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const StyledTitle6 = styled(Title4)`
  padding-bottom: ${theme.spacing.large}px;
`

const InactiveServicesContainer = styled.div`
  width: 100%;
  background-color: ${theme.color.beigeDark};
`
