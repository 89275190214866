export const splitArrayInto2and4Chunks = <T>(array: Array<T>): Array<Array<T>> => {
  const chunks = []
  for (let i = 0; i < array.length; ) {
    if (chunks.length % 2 === 0) {
      chunks.push(array.slice(i, i + 2))
      i += 2
    } else {
      chunks.push(array.slice(i, i + 4))
      i += 4
    }
  }
  return chunks
}
