import { FC } from 'react'
import styled from 'styled-components'
import {
  BodyTiny,
  Label,
  BodyXl,
  BodyLarge,
  Title3,
  Title1,
  Title2,
  Title4,
  Title5,
  BodySmall,
  Display,
  Title6,
  SmallNavigation,
} from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { Grid } from '../../components/Grid'

const fontItems: FontItem[] = [
  {
    fontFamily: theme.font.heading,
    fontSize: 25,
    desktopFontSize: 12,
    lineHeight: 1.1,
    desktopLineHeight: 1.1,
    fontItem: <Display>Display</Display>,
  },
  {
    fontFamily: theme.font.heading,
    fontSize: theme.fontSize.xlarge,
    desktopFontSize: theme.fontSize.jumbo,
    lineHeight: 38,
    desktopLineHeight: 52,
    fontItem: <Title1>Title1</Title1>,
  },
  {
    fontFamily: theme.font.heading,
    fontSize: theme.fontSize.xlarge,
    desktopFontSize: theme.fontSize.xxlarge,
    lineHeight: 38,
    desktopLineHeight: 44,
    fontItem: <Title2>Title2</Title2>,
  },
  {
    fontFamily: theme.font.heading,
    fontSize: theme.fontSize.large,
    desktopFontSize: theme.fontSize.xlarge,
    lineHeight: 30,
    desktopLineHeight: 38,
    fontItem: <Title3>Title3</Title3>,
  },
  {
    fontFamily: theme.font.heading,
    fontSize: theme.fontSize.medium,
    desktopFontSize: theme.fontSize.large,
    lineHeight: 26,
    desktopLineHeight: 30,
    fontItem: <Title4>Title4</Title4>,
  },
  {
    fontFamily: theme.font.heading,
    fontSize: theme.fontSize.default,
    desktopFontSize: theme.fontSize.medium,
    lineHeight: 20,
    desktopLineHeight: 26,
    fontItem: <Title5>Title5</Title5>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.default,
    desktopFontSize: theme.fontSize.default,
    lineHeight: 20,
    desktopLineHeight: 20,
    fontItem: <Title6>Title6</Title6>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.medium,
    desktopFontSize: theme.fontSize.mediumLarge,
    lineHeight: 26,
    desktopLineHeight: 29,
    fontItem: <BodyXl>BodyXl</BodyXl>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.default,
    desktopFontSize: theme.fontSize.default,
    lineHeight: 24,
    desktopLineHeight: 24,
    fontItem: <BodyLarge>BodyLarge</BodyLarge>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.small,
    desktopFontSize: theme.fontSize.default,
    lineHeight: 24,
    desktopLineHeight: 24,
    fontItem: <BodyLarge>BodyMedium</BodyLarge>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.small,
    desktopFontSize: theme.fontSize.small,
    lineHeight: 22,
    desktopLineHeight: 22,
    fontItem: <BodySmall>BodySmall</BodySmall>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.xsmall,
    desktopFontSize: theme.fontSize.xsmall,
    lineHeight: 16,
    desktopLineHeight: 16,
    fontItem: <BodyTiny>BodyTiny</BodyTiny>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.xxsmall,
    desktopFontSize: theme.fontSize.xxsmall,
    lineHeight: 16,
    desktopLineHeight: 16,
    fontItem: <SmallNavigation>SmallNavigation</SmallNavigation>,
  },
  {
    fontFamily: theme.font.body,
    fontSize: theme.fontSize.xxsmall,
    desktopFontSize: theme.fontSize.xxsmall,
    lineHeight: 16,
    desktopLineHeight: 16,
    fontItem: <Label>Label</Label>,
  },
]

export const Typography = () => {
  return (
    <ComponentLayout>
      <StyledTitle1>Typography</StyledTitle1>
      <Grid $columns={2}>
        {fontItems.map((item, index) => {
          return (
            <FontItem
              key={index}
              fontFamily={item.fontFamily}
              fontSize={item.fontSize}
              desktopFontSize={item.desktopFontSize}
              lineHeight={item.lineHeight}
              desktopLineHeight={item.desktopLineHeight}
              fontItem={item.fontItem}
            />
          )
        })}
      </Grid>
    </ComponentLayout>
  )
}

interface FontItem {
  fontFamily: string
  fontSize: number
  desktopFontSize: number
  desktopLineHeight: number
  lineHeight: number
  fontItem: JSX.Element
}

const FontItem: FC<FontItem> = ({ fontFamily, fontSize, desktopFontSize, lineHeight, desktopLineHeight, fontItem }) => {
  const formattedFont = fontFamily.replace(/-/g, ' ')
  return (
    <Item>
      {fontItem}
      <BodyLarge>
        <strong>Font: </strong>
        {formattedFont}
      </BodyLarge>
      <BodyLarge>
        <strong>Size:</strong> {fontSize} px
      </BodyLarge>
      <BodyLarge>
        <strong>Size (desktop):</strong> {desktopFontSize} px
      </BodyLarge>
      <BodyLarge>
        <strong>Line height:</strong> {lineHeight} px
      </BodyLarge>
      <BodyLarge>
        <strong>Line height (desktop):</strong> {desktopLineHeight} px
      </BodyLarge>
    </Item>
  )
}

const StyledTitle1 = styled(Title1)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const Item = styled.div`
  width: 100%;
  border-bottom-width: 1px;
  border: 1px solid ${theme.color.black};
  padding: 1rem;
  border-radius: 5px;
`
