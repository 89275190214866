import { theme } from '../../theme/theme'
import { IconProps } from './types'
const DEFAULT_SIZE = 60
const DEFAULT_COLOR = theme.color.plum

export const Menu = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4853 18.4853H3.51472" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.51472 11.9999L20.4853 11.9999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.51472 5.48528L20.4853 5.48528" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
