import { theme } from '../../theme/theme'
import { IconProps } from './types'

const DEFAULT_SIZE = 24
const DEFAULT_COLOR = theme.color.black

export const Caret = ({ size = DEFAULT_SIZE, color = DEFAULT_COLOR, ...rest }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M15 12.8074L15.3536 12.4538L15.7071 12.8074L15.3536 13.1609L15 12.8074ZM9.35355 6.45382L15.3536 12.4538L14.6464 13.1609L8.64645 7.16093L9.35355 6.45382ZM15.3536 13.1609L9.35355 19.1609L8.64645 18.4538L14.6464 12.4538L15.3536 13.1609Z"
      fill={color}
    />
  </svg>
)
