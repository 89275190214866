export enum FormTypes {
  BIRTH_YEAR = 'birthYear',
  GENDER = 'gender',
  ZIP_CODE = 'zipCode',
}

export type FormData = {
  [key in FormTypes]: string
} & {
  gender: 'M' | 'F' | 'U' | ''
}

export type FormError = {
  formType: FormTypes | undefined
  errorMessage: string
}

export type SelectOption = {
  value: string
  label: string
}
