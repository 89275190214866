import { FC, Fragment } from 'react'
import {
  isIFAQ,
  isITextWithImageBlock,
  isIHighlightedListBlock,
  isICenteredTextBlock,
  isImageBlock,
} from '@estercare/ester-shared'
import { AnyBlock } from '../contentful/types'
import { TextWithImageCard } from './TextWithImageCard'
import { FAQ } from './Faq'
import { ListScreenItems } from './ListScreenItems'
import { CenteredText } from './CenteredText'
import { ImageBlock } from './ImageBlock'

export const DynamicBlock: FC<{ block: AnyBlock }> = ({ block }) => {
  if (isITextWithImageBlock(block)) {
    return <TextWithImageCard {...block.fields} />
  }

  if (isIFAQ(block)) {
    return <FAQ {...block.fields} />
  }

  if (isIHighlightedListBlock(block)) {
    return <ListScreenItems {...block.fields} />
  }

  if (isICenteredTextBlock(block)) {
    return <CenteredText {...block.fields} />
  }

  if (isImageBlock(block)) {
    return <ImageBlock {...block.fields} />
  }

  return <Fragment />
}
