import styled from 'styled-components'
import { StackedBarChart } from '../../components/charts/StackedBarChart'
import { BodyLarge, BodySmall, Title2, Title6 } from '../../components/styles/Typography'
import { theme } from '../../theme/theme'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { useSelectUserOrganisationId } from '../../state/selectors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { TrainingId } from '@estercare/ester-shared'
import { getTrainingData } from '../../utils/statistics/chartData'
import { useGetUsersFromOrganisationQuery } from '../../api/ester-b2b-api/private/users/userQueries'
import { useGetTrainingStatistics } from '../../api/ester-b2b-api/private/trainings/trainingQueries'

export const Statistics = () => {
  const organisationId = useSelectUserOrganisationId()
  const { data: womensHealthStatistics, isLoading: isWomensHealthStatisticsLoading } = useGetTrainingStatistics(
    TrainingId.WOMENS_HEALTH_TRAINING
  )
  const { data: employees, isLoading: isUsersLoading } = useGetUsersFromOrganisationQuery(organisationId)

  if (isWomensHealthStatisticsLoading || isUsersLoading) return <LoadingSpinner />

  const numberOfEmployees = employees?.length

  return (
    <ComponentLayout>
      <StyledTitle2>Statistik</StyledTitle2>
      <StyledBodyLarge>Följ deltagande och välmående hos era anställda över tid.</StyledBodyLarge>
      <ChartWrapper>
        <Divider />
        <ChartTitle>Utbildning</ChartTitle>
        {womensHealthStatistics && numberOfEmployees && (
          <StackedBarChart
            data={getTrainingData(womensHealthStatistics, numberOfEmployees)}
            title="Deltagande i utbildning om kvinnohälsa"
          />
        )}
      </ChartWrapper>
      <BodySmall>Mer statistik kommer visas här framöver.</BodySmall>
    </ComponentLayout>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const StyledBodyLarge = styled(BodyLarge)`
  margin-bottom: ${theme.spacing.large}px;
`

const ChartTitle = styled(Title6)`
  margin-bottom: ${theme.spacing.medium}px;
`

const ChartWrapper = styled.div`
  margin-bottom: ${theme.spacing.large}px;
`

const Divider = styled.hr`
  margin-bottom: ${theme.spacing.large}px;
  border: none;
  height: 1px;
  background-color: ${theme.color.beigeDark};
`
