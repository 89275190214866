import { IArticleFields, IPodcastEpisodeFields, isDocument } from '@estercare/ester-shared'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { theme } from '../theme/theme'
import { Grid } from './Grid'
import { PreviewGuard } from './PreviewGuard'
import { Title6 } from './styles/Typography'
import { BlockItem } from './BlockItem'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { OnClickButton } from './buttons/onclick/OnClickButton'

import { CollectionCard } from './CollectionCard'

export interface Params {
  title: string
  items: IPodcastEpisodeFields[] | IArticleFields[]
}

export const ResultList: FC<Params> = ({ title, items }) => {
  const startingContentShownCount = 8
  const loadMoreContentCount = 18

  const isPodcastEpisodeFields = (podcast: unknown): podcast is IPodcastEpisodeFields => {
    return (podcast as IPodcastEpisodeFields)?.episodeNumber !== undefined
  }

  const [podcastIndex, setContentIndex] = useState<number>(startingContentShownCount)

  const latestContent =
    Array.isArray(items) && isPodcastEpisodeFields(items[0])
      ? // Array.sort mutates the original array, so we need to make a copy, otherwise we'll risk breaking other components
        [...items].sort((podCastA, podcastB) => {
          if (isPodcastEpisodeFields(podcastB) && isPodcastEpisodeFields(podCastA)) {
            return (podcastB.episodeNumber || 0) - (podCastA.episodeNumber || 0)
          }
          return 0
        })
      : items

  const displayedContent = latestContent.slice(0, podcastIndex)
  const moreContentsToShow = displayedContent.length < items.length - 1

  return (
    <ContentContainer>
      {title && <StyledTitle6>{title}</StyledTitle6>}
      <StyledGrid>
        {Array.isArray(displayedContent) &&
          displayedContent.map((item, index) => {
            if (isPodcastEpisodeFields(item)) {
              const { slug, title, episodeNumber, featuredImage, description } = item

              if (!title || !slug || !featuredImage || !description) {
                return <PreviewGuard key={index} />
              }
              const href = `/portal/kunskapsbank/podcasts/${slug}`
              const podSubtitle = isDocument(description)
                ? documentToPlainTextString(description)
                : 'Saknar beskrivning'

              return (
                <BlockItem height={450} key={index}>
                  <CollectionCard
                    slug={href}
                    title={title}
                    abstract={podSubtitle}
                    label={`Avsnitt ${episodeNumber}`}
                    image={featuredImage}
                  />
                </BlockItem>
              )
            } else {
              const { slug, title, primaryImage, abstract } = item

              if (!title || !slug || !primaryImage || !abstract) {
                return <PreviewGuard key={index} />
              }
              const href = `/portal/kunskapsbank/artiklar/${slug}`

              return (
                <BlockItem height={450} key={index}>
                  <CollectionCard slug={href} title={title} abstract={abstract} label="Artikel" image={primaryImage} />
                </BlockItem>
              )
            }
          })}
      </StyledGrid>
      {moreContentsToShow && (
        <ButtonContainer>
          <OnClickButton
            text="Visa fler"
            secondary
            onClick={() => setContentIndex((prevValue) => prevValue + loadMoreContentCount)}
          />
        </ButtonContainer>
      )}
    </ContentContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: ${theme.spacing.xxlarge}px;
`
const StyledTitle6 = styled(Title6)`
  margin-bottom: ${theme.spacing.small}px;

  @media screen and (min-width: ${theme.breakpoint.medium}px) {
    margin-bottom: ${theme.spacing.xsmall}px;
  }
`
const StyledGrid = styled(Grid)<{ $horizontal?: boolean }>`
  margin-top: ${theme.spacing.medium}px;

  ${({ $horizontal }) =>
    !$horizontal &&
    `
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      gap: ${theme.spacing.xsmall}px;
      justify-content: space-between;
      
      @media screen and (max-width: ${theme.breakpoint.xlarge}px) {
        grid-template-columns: repeat(2, minmax(230px, 1fr));
      }

      @media screen and (max-width: ${theme.breakpoint.small}px) {
        justify-content: center;
      }

      @media screen and (max-width: ${theme.breakpoint.xsmall}px) {
        grid-template-columns: repeat(1, minmax(230px, 350px));
      }

    `}
`
