import { FC, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelectIsAdmin } from '../../state/selectors'

export const AdminViewGuard: FC = () => {
  const navigate = useNavigate()
  const isAdmin = useSelectIsAdmin()

  useEffect(() => {
    if (!isAdmin) {
      navigate('/portal')
    }
  }, [isAdmin, navigate])

  return isAdmin ? <Outlet /> : null
}
