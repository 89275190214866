import styled from 'styled-components'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'
import { BodyLargeMedium, BodySmall, BodySmallMedium, Title2 } from '../../components/styles/Typography'
import { Arrow, InvoiceBlank, User } from '../../components/icons'
import { theme } from '../../theme/theme'
import { StackedBarChart } from '../../components/charts/StackedBarChart'
import { useGetServicePages } from '../../api/contentful/contentfulQueries'
import { TrainingId } from '@estercare/ester-shared'
import { Link } from 'react-router-dom'
import * as IconComponents from '../../components/icons'
import React from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { ServiceCard } from '../../components/ServiceCard'
import { useSelectActiveServicesFromContentful, useSelectUserOrganisationId } from '../../state/selectors'
import { getTrainingData } from '../../utils/statistics/chartData'
import { useGetUsersFromOrganisationQuery } from '../../api/ester-b2b-api/private/users/userQueries'
import { useGetTrainingStatistics } from '../../api/ester-b2b-api/private/trainings/trainingQueries'
import { Grid } from '../../components/Grid'
import { BlockItem } from '../../components/BlockItem'

const getStartedCardArr = [
  {
    icon: <User size={25} color={theme.color.white} />,
    largeText: 'Hantera medarbetare',
    smallText: 'Lägg till, ta bort och hantera medarbetare i ditt företag.',
    link: 'medarbetare',
  },
  {
    icon: <InvoiceBlank size={21} color={theme.color.white} />,
    largeText: 'Policy för kvinnohälsa',
    smallText: 'Använd vår mall och ta fram en policy för kvinnohälsa på er arbetsplats.',
    link: 'tjanster/policy',
  },
]

export const HomeAdmin = () => {
  const { data, isLoading: isLoadingServices } = useGetServicePages()
  const ServiceIndex = data?.items || []
  const activeServices = useSelectActiveServicesFromContentful(ServiceIndex, 'ADMIN')

  const organisationId = useSelectUserOrganisationId()
  const { data: womensHealthStatistics, isLoading: isWomensHealthStatisticsLoading } = useGetTrainingStatistics(
    TrainingId.WOMENS_HEALTH_TRAINING
  )
  const { data: employees, isLoading: isUsersLoading } = useGetUsersFromOrganisationQuery(organisationId)

  if (isWomensHealthStatisticsLoading || isUsersLoading || isLoadingServices) return <LoadingSpinner />

  const numberOfEmployees = employees?.length

  const SeeAllLink = ({ link, text }: { link: string; text: string }) => (
    <StyledLink to={link}>
      <BodySmallMedium>{text}</BodySmallMedium>
      <Arrow size={16} />
    </StyledLink>
  )

  return (
    <>
      <ComponentLayout paddingBottom={0}>
        <StyledTitle2>Välkommen till EsterCare för företag!</StyledTitle2>
      </ComponentLayout>
      <ComponentLayout>
        <StyledBodyLargeMedium>Tips för att komma igång</StyledBodyLargeMedium>
        <Grid $columns={2}>
          {getStartedCardArr.map(({ largeText, icon, smallText, link }, index) => (
            <Link to={link} key={`link-${index}`}>
              <BlockItem
                key={index}
                backgroundColor={theme.color.plumMid}
                padding={theme.spacing.large}
                textColor={theme.color.white}
                height={200}
              >
                <GetStartedIconWrapper>{icon}</GetStartedIconWrapper>
                <CardTitle>{largeText}</CardTitle>
                <BodySmall>{smallText}</BodySmall>
              </BlockItem>
            </Link>
          ))}
        </Grid>
      </ComponentLayout>
      {activeServices.length > 0 && (
        <ComponentLayout paddingTop={0}>
          <TitleContainer>
            <BodyLargeMedium>Dina tjänster</BodyLargeMedium>
            {SeeAllLink({ link: '/admin/tjanster', text: 'Se alla tjänster' })}
          </TitleContainer>
          <Grid $columns={3}>
            {activeServices.map(({ fields: { title, shortDescription, slug, iconType }, sys: { id } }) => (
              <ServiceCard
                key={id}
                title={title!}
                description={shortDescription || ''}
                linkTo={`tjanster/${slug}`}
                icon={
                  iconType &&
                  React.createElement(IconComponents[iconType as keyof typeof IconComponents], {
                    size: 22,
                    color: theme.color.plumMid,
                  })
                }
              />
            ))}
          </Grid>
        </ComponentLayout>
      )}
      <ComponentLayout paddingTop={0}>
        <TitleContainer>
          <BodyLargeMedium>Deltagande</BodyLargeMedium>
          {SeeAllLink({ link: '/admin/statistik', text: 'Se all statistik' })}
        </TitleContainer>
        {womensHealthStatistics && numberOfEmployees && (
          <StackedBarChart
            data={getTrainingData(womensHealthStatistics, numberOfEmployees)}
            title="Deltagande i utbildning om kvinnohälsa"
          />
        )}
      </ComponentLayout>
    </>
  )
}

const StyledTitle2 = styled(Title2)`
  max-width: 400px;
`

const TitleContainer = styled.div`
  margin-bottom: ${theme.spacing.medium}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledBodyLargeMedium = styled(BodyLargeMedium)`
  margin-bottom: ${theme.spacing.medium}px;
`

const CardTitle = styled(BodyLargeMedium)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const GetStartedIconWrapper = styled.div`
  padding: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${theme.color.white}15;
  margin-bottom: ${theme.spacing.medium}px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${theme.color.plumMid};
  gap: 3px;
`
