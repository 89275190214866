import { FC } from 'react'
import { theme } from '../../../theme/theme'
import { LoadingCheck } from '../../LoadingCheck'
import { ButtonProps, Button } from '../Button'

interface LinkButtonProps extends ButtonProps {
  href: string
}

export const LinkButton: FC<LinkButtonProps> = ({
  href,
  buttonStyle,
  secondary,
  buttonColor = theme.color.plum,
  loading,
  text,
  disabled,
}) => {
  return (
    <Button to={href} style={buttonStyle} $disabled={disabled} $secondary={secondary} $buttonColor={buttonColor}>
      {LoadingCheck(text, loading)}
    </Button>
  )
}
