import { Options as RichTextRenderOptions } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { Title6, RichTextLink, BodyLarge, BodySmall, Blockquote, Title3 } from '../styles/Typography'
import { findPathByEntry } from '../../contentful/utils'

export const getOptions = (bodySmall?: boolean): RichTextRenderOptions => ({
  renderNode: {
    [BLOCKS.HEADING_5]: (_, children): ReactNode => <Title3>{children}</Title3>,
    [BLOCKS.HEADING_6]: (_, children): ReactNode => <Title6>{children}</Title6>,
    [BLOCKS.QUOTE]: (_, children): ReactNode => <Blockquote>{children}</Blockquote>,
    [BLOCKS.PARAGRAPH]: (_, children): ReactNode => {
      return bodySmall ? <BodySmall>{children}</BodySmall> : <BodyLarge>{children}</BodyLarge>
    },
    [INLINES.HYPERLINK]: (node, children): ReactNode => <RichTextLink href={node.data.uri}>{children}</RichTextLink>,
    [INLINES.ENTRY_HYPERLINK]: (node, children): ReactNode => {
      const isCircularReference = node.data?.target === '[Circular]'
      if (isCircularReference) {
        return <RichTextLink href="#">{children}</RichTextLink>
      }
      return <RichTextLink href={findPathByEntry(node.data?.target)}>{children}</RichTextLink>
    },
  },
})
