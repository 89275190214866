import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { User } from '../api/ester-b2b-api/private/users/types'
import { Services } from '@estercare/ester-shared'

type ServiceStoreState = {
  activeServices: Services[]
  setActiveServices: (services: Services[]) => void
}

export type UserStoreState = {
  user?: User
  setUser: (user: User) => void
}

export const useUserStore = create<UserStoreState>()(
  devtools((set) => ({
    user: undefined,
    setUser: (user) => set({ user }),
  }))
)

export const useServicesStore = create<ServiceStoreState>()(
  devtools((set) => ({
    activeServices: [],
    setActiveServices: (activeServices) =>
      set({
        activeServices,
      }),
  }))
)
