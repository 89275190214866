import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Spinner } from './icons/Spinner'
import { theme } from '../theme/theme'
import { Asset } from 'contentful'
import { PreviewGuard } from './PreviewGuard'
import { Play } from './icons/Play'
import styled from 'styled-components'
import { parseContenfulImageUrl } from '../contentful/utils'

type Props = {
  video: Asset
}

export const VideoPlayer = ({ video }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const playerRef = useRef<ReactPlayer | null>(null)
  const videoAlt = video?.fields?.description || video?.fields?.title || 'EsterCare'

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return (
      <LoadingContainer>
        <Spinner height={50} width={50} color={theme.color.plum} />
      </LoadingContainer>
    )
  }

  return video?.fields ? (
    <Container>
      <StyledReactPlayer
        ref={playerRef}
        url={parseContenfulImageUrl(video)}
        alt={videoAlt}
        playing={isPlaying}
        controls={isPlaying}
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
      {!isPlaying && (
        <PlayButton onClick={() => setIsPlaying(true)}>
          <Play width={100} height={100} />
        </PlayButton>
      )}
    </Container>
  ) : (
    <PreviewGuard />
  )
}

const StyledReactPlayer = styled(ReactPlayer)`
  video {
    display: block;
    border-radius: ${theme.spacing.medium}px;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
`
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
`
