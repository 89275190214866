import { FC } from 'react'
import { IArticle, IPodcastEpisode, IStory, isArticle, isPodcast, isStory } from '@estercare/ester-shared'
import { PreviewGuard } from './PreviewGuard'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { getOptions } from './richText/options'
import { CollectionCard } from './CollectionCard'

interface Props {
  item: IArticle | IPodcastEpisode | IStory
}

export const CollectionItem: FC<Props> = ({ item }) => {
  if (isArticle(item)) {
    const { slug, title, abstract, primaryImage } = item.fields
    const label = 'Artikel'
    const completeSlug = `/portal/kunskapsbank/artiklar/${slug}`

    if (!title || !abstract || !primaryImage) {
      return <PreviewGuard />
    }
    return (
      <CollectionCard
        horizontal
        slug={completeSlug}
        title={title}
        abstract={abstract}
        label={label}
        image={primaryImage}
      />
    )
  }
  if (isPodcast(item)) {
    const { slug, title, episodeNumber, featuredImage, description } = item.fields
    const label = `Avsnitt ${episodeNumber}`
    const completeSlug = `/portal/kunskapsbank/podcasts/${slug}`
    if (!title || !description || !featuredImage || !episodeNumber) return <PreviewGuard />
    return (
      <CollectionCard
        slug={completeSlug}
        title={title}
        abstract={description && documentToReactComponents(description, getOptions(true))}
        label={label}
        image={featuredImage}
        episodeNumber={episodeNumber}
      />
    )
  }
  if (isStory(item)) {
    return <PreviewGuard />
  }
  return <PreviewGuard />
}
