import { UserDataMessage } from '@estercare/ester-shared'
import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Permission, UsersFromOrganisationDb } from '../../api/ester-b2b-api/private/users/types'
import {
  useUpdateUserMutation,
  useGetUsersFromOrganisationQuery,
  useDeleteUserMutation,
  usePostUsers,
} from '../../api/ester-b2b-api/private/users/userQueries'
import { Alert, AlertTypes } from '../../components/Alert'
import { OnClickButton } from '../../components/buttons/onclick/OnClickButton'
import { LoadingState, DropZone } from '../../components/DropZone'
import { Close, SearchLight } from '../../components/icons'
import { ComponentLayout } from '../../components/layouts/ComponentLayout'

import { BodyLarge, BodySmall, Title2, Title4 } from '../../components/styles/Typography'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import { useSelectUserOrganisationId } from '../../state/selectors'
import { theme } from '../../theme/theme'
import { isMobile, isTablet } from '../../utils/devices'

import { Modal } from '../../components/Modal'
import { datadogRum } from '@datadog/browser-rum'
import { EmployeesTable } from '../../components/employees/EmployeesTable'

type AlertType = {
  message?: string
  type?: AlertTypes
}

enum FileUploadState {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

const errorMessages: Record<UserDataMessage, string> = {
  [UserDataMessage.TOO_SHORT]: 'Filen behöver innehålla minst två rader med data.',
  [UserDataMessage.MISSING_FIELDS]: 'Filen behöver innehålla minst tre kolumner: Email, förnamn och efternamn.',
  [UserDataMessage.MISSING_FIELD]: 'En eller flera rader saknar värden i en eller flera kolumner.',
  [UserDataMessage.INVALID_EMAIL]: 'Ogiltig e-postadress på en eller flera rader.',
  [UserDataMessage.VALID]: 'Filen är giltig.',
  [UserDataMessage.MANAGER_NOT_A_BOOLEAN]: 'Columnen för chefsposition måste anges som ett "true" eller "false" värde.',
}

export const Employees = () => {
  const organisationId = useSelectUserOrganisationId()
  const [search, setSearch] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState<UsersFromOrganisationDb>()
  const [showDropZone, setShowDropZone] = useState(false)
  const [fileUploadState, setFileUploadState] = useState<LoadingState>(null)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [alertState, setAlertState] = useState<AlertType>({})
  const width = useWindowWidth()
  const isMobileDevice = isMobile(width) || isTablet(width)

  const { mutate: updateUserMutation } = useUpdateUserMutation()
  const { data: users = [], refetch: refetchGetUsersFromOrganisationQuery } = useGetUsersFromOrganisationQuery(
    organisationId || ''
  )
  const { mutate: deleteUser } = useDeleteUserMutation()
  const { mutate: postUser, isSuccess, isError, isPending, error, data } = usePostUsers()

  const noOfCreatedUsers = data?.createdUsers.length
  const noOfExistingUsers = data?.alreadyExistingUsers.length

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (alertState.message) {
      const timer = setTimeout(() => {
        setAlertState({
          message: undefined,
          type: undefined,
        })
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [alertState.message])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const onDeleteUser = () => {
    if (selectedEmployee) {
      deleteUser(selectedEmployee.id, {
        onSuccess: () => {
          refetchGetUsersFromOrganisationQuery()
          setAlertState({
            message: 'Användaren raderad',
            type: 'success',
          })
        },
        onError: (error) => {
          datadogRum.addError(error)
          setAlertState({
            message: 'Det gick inte att ta bort användaren, vänligen försök igen.',
            type: 'error',
          })
        },
        onSettled: () => {
          setOpenDialog(false)
        },
      })
    }
  }

  const onUpdateUser = (userId: string, newPermission: Permission) =>
    updateUserMutation(
      { id: userId, newUserData: { permission: newPermission } },
      {
        onSuccess: () => {
          refetchGetUsersFromOrganisationQuery()
          const successMessage =
            newPermission === 'ADMIN'
              ? 'Användaren har fått adminbehörighet.'
              : 'Användarens adminbehörighet är borttagen.'

          setAlertState({
            message: successMessage,
            type: 'success',
          })
        },
        onError: (error) => {
          setAlertState({
            message: error.message || 'Det gick inte att uppdatera användaren.',
            type: 'error',
          })
        },
      }
    )

  useEffect(() => {
    isPending && setFileUploadState(FileUploadState.LOADING)
    if (isSuccess) {
      setFileUploadState(FileUploadState.SUCCESS)
      setAlertState({
        message: `${noOfCreatedUsers ? `${noOfCreatedUsers} medarbetare tillagda. ` : ''}${noOfExistingUsers ? `${noOfExistingUsers} medarbetare fanns redan i systemet.` : ''}`,
        type: 'success',
      })
    }
    if (isError) {
      setFileUploadState(FileUploadState.FAILED)
      const errorType = Object.keys(errorMessages).find((key) => error.message.includes(key))
      if (errorType) setErrorMessage(errorMessages[errorType as UserDataMessage])
    }
  }, [isSuccess, isError, isPending, error, noOfCreatedUsers, noOfExistingUsers])

  const uploadFile = useCallback(
    (files: File[]) => {
      if (files.length > 0) {
        const usersFileData = new FormData()
        files.forEach((file) => usersFileData.append('file', file))
        postUser({ usersFileData })
      }
    },
    [postUser]
  )

  const searchIcon =
    search.length > 0 ? (
      <Close size={24} color={theme.color.grey5} />
    ) : (
      <SearchLight size={24} color={theme.color.grey5} />
    )

  const filteredEmployees = users.filter((employee) => {
    const searchLower = search.toLowerCase()
    const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase()
    const email = employee.email.toLowerCase()
    return fullName.includes(searchLower) || email.includes(searchLower)
  })

  return (
    <>
      <ComponentLayout>
        <div>
          <StyledTitle2>Medarbetare ({users.length} st) </StyledTitle2>
          <BodyLarge>Administrera vilka medarbetare som har tillgång till hälsoportalen.</BodyLarge>
        </div>

        <AlertWrapper>
          {alertState.message && <Alert message={alertState.message} type={alertState.type || 'success'} />}
        </AlertWrapper>
        <InputContainer $isMobile={isMobileDevice}>
          <SearchContainer>
            <SearchInput type="text" name="searchBar" placeholder="Sök" value={search} onChange={handleSearch} />
            {searchIcon}
          </SearchContainer>
          <OnClickButton text="Lägg till medarbetare" onClick={() => setShowDropZone(true)} />
        </InputContainer>

        <EmployeesTable
          onSelectedEmployee={setSelectedEmployee}
          openDialog={() => setOpenDialog(true)}
          employees={filteredEmployees}
          onUpdateUser={onUpdateUser}
        />
      </ComponentLayout>

      {openDialog && (
        <Modal onClose={() => setOpenDialog(false)}>
          <ModalFlexWrapper>
            <div>
              <StyledTitle4 style={{ marginBottom: '5px' }}>Radera användare</StyledTitle4>
              <BodyLarge>
                Användare
                <b>{` ${selectedEmployee?.firstName} ${selectedEmployee?.lastName} `}</b>
                kommer att raderas permanent. <br /> Är du säker på att du vill fortsätta?
              </BodyLarge>
            </div>

            <OnClickButton secondary text="Fortsätt" onClick={onDeleteUser} />
          </ModalFlexWrapper>
        </Modal>
      )}
      {showDropZone && (
        <Modal
          onClose={() => {
            setShowDropZone(false)
            setFileUploadState(null)
          }}
          backgroundColor={theme.color.beigeLight}
        >
          <ModalTitle>Lägg till flera medarbetare</ModalTitle>
          <BodySmall>Lägg till flera medarbetare samtidigt genom att lista dem i en CSV-fil.</BodySmall>
          <StyledBodySmall>Använd vår mall för att formatera filen på ett korrekt sätt.</StyledBodySmall>
          <DropZone
            acceptedFiles={{ 'text/csv': ['.csv'] }}
            onDrop={(files) => uploadFile(files)}
            fileUploadState={fileUploadState}
            errorMessage={errorMessage}
          />
        </Modal>
      )}
    </>
  )
}

const StyledTitle2 = styled(Title2)`
  margin-bottom: ${theme.spacing.xsmall}px;
`

const SearchContainer = styled.div`
  background-color: ${theme.color.white};
  border-radius: 50px;
  box-shadow: 0px 0px 2px 0.5px rgba(0, 0, 0, 0.15);
  border: none;
  width: 330px;
  height: ${theme.spacing.xxlarge}px;
  display: flex;
  align-items: center;
  padding: 0 ${theme.spacing.small}px;
  &:focus-within {
    border: 1px solid ${theme.color.plumMid};
  }
  @media screen and (max-width: ${theme.breakpoint.xsmall}px) {
    width: 100%;
  }
`

const SearchInput = styled.input`
  width: 100%;
  font-size: ${theme.fontSize.small}px;
  font-family: ${theme.font.body};
  color: ${theme.color.grey4};
  height: 90%;
  border-radius: 50px;
  border: none;
  outline: none;
  &:focus {
    color: ${theme.color.grey5};
  }
`
const InputContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ $isMobile }) => ($isMobile ? theme.spacing.large : theme.spacing.mediumLarge)}px;
  button {
    margin-top: ${({ $isMobile }) => ($isMobile ? theme.spacing.medium : 0)}px;
    @media screen and (max-width: 450px) {
      width: 100%;
    }
    width: ${({ $isMobile }) => ($isMobile ? '330px' : '200px')};
  }
`

const ModalFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  max-width: 500px;
  gap: ${theme.spacing.medium}px;
`

const StyledTitle4 = styled(Title4)`
  margin-bottom: ${theme.spacing.xsmall}px;
`
const AlertWrapper = styled.div`
  margin-top: ${theme.spacing.large}px;
  position: relative;
  height: 46px;
  overflow: hidden;
  margin-bottom: ${theme.spacing.mediumLarge}px;
`

const ModalTitle = styled(Title4)`
  margin-bottom: ${theme.spacing.medium}px;
`

const StyledBodySmall = styled(BodySmall)`
  margin-bottom: ${theme.spacing.mediumLarge}px;
`
