import styled, { keyframes } from 'styled-components'
import { BodySmall } from './styles/Typography'
import { Success, ErrorSign } from './icons'
import { theme } from '../theme/theme'

export type AlertTypes = 'success' | 'error'

type Props = {
  message?: string
  type?: AlertTypes
}

export const Alert = ({ message, type = 'success' }: Props) => {
  const Icon = type === 'success' ? Success : ErrorSign
  const Container = type === 'success' ? AlertContainerSuccess : AlertContainerError

  return (
    <Container $isVisible={Boolean(message)}>
      <Icon size={25} /> <BodySmall>{message}</BodySmall>
    </Container>
  )
}

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`

const BaseAlertContainer = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  width: 100%;
  height: 46px;
  border-radius: ${theme.spacing.xsmall}px;
  padding: 0px ${theme.spacing.medium}px;
  animation: ${({ $isVisible }) => ($isVisible ? slideDown : slideUp)} 0.2s ease-in-out forwards;
  gap: ${theme.spacing.xsmall}px;
  display: flex;
  align-items: center;
`

const AlertContainerSuccess = styled(BaseAlertContainer)`
  background-color: ${theme.color.greenLight};
  color: ${theme.color.greenDark};
  border: 1px solid ${theme.color.greenDark};
`

const AlertContainerError = styled(BaseAlertContainer)`
  background-color: ${theme.color.redLight};
  color: ${theme.color.redDark};
  border: 1px solid ${theme.color.redDark};
`
